import { PortableTextTypeComponent } from '@portabletext/react'
import { useNextSanityImage } from 'next-sanity-image'

import { Image as ImageComponent } from 'src/components/atoms/Image'
import { ParagraphSmaller } from 'src/components/atoms/Typography'

import {
  Body,
  SanityImageAsset,
  SanityReference
} from 'src/config/generated/sanitySchema'
import { ArrayElement } from 'src/types/common'
import { sanityClient } from 'src/utils/sanity'

export const Image: PortableTextTypeComponent<ArrayElement<Body>> = ({
  value
}) => {
  const { asset, alt, label } = value

  const imageProps = useNextSanityImage(
    sanityClient,
    asset as SanityReference<SanityImageAsset>
  )

  return (
    <div className='text-center bg-linen'>
      <ImageComponent {...imageProps} alt={alt as string} />
      {label && (
        <ParagraphSmaller className='font-medium !pb-[10px] pt-[4px] leading-none'>
          {label}
        </ParagraphSmaller>
      )}
    </div>
  )
}
