import * as Yup from 'yup'

const validationSchema = Yup.object({
  Email: Yup.string().email().required(),
  RecipientFirstname: Yup.string().required(),
  RecipientLastname: Yup.string().required(),
  Message: Yup.string().required(),
  AccountId: Yup.string().required()
})

export type ResendInviteFormValues = Yup.InferType<typeof validationSchema>

export default validationSchema
