import { IconProps } from './types'

export const MapView = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.7319 18.5955C12.5708 16.3326 16.7647 10.8481 16.7647 7.76751C16.7647 4.03128 13.682 1 9.88235 1C6.08272 1 3 4.03128 3 7.76751C3 10.8481 7.19393 16.3326 9.03281 18.5955C9.47371 19.1348 10.291 19.1348 10.7319 18.5955ZM9.88235 5.51168C10.4908 5.51168 11.0743 5.74934 11.5045 6.17239C11.9348 6.59545 12.1765 7.16923 12.1765 7.76751C12.1765 8.3658 11.9348 8.93958 11.5045 9.36263C11.0743 9.78568 10.4908 10.0234 9.88235 10.0234C9.27392 10.0234 8.6904 9.78568 8.26017 9.36263C7.82994 8.93958 7.58824 8.3658 7.58824 7.76751C7.58824 7.16923 7.82994 6.59545 8.26017 6.17239C8.6904 5.74934 9.27392 5.51168 9.88235 5.51168Z'
        fill='currentColor'
      />
    </svg>
  )
}
