import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { Heading5 } from 'src/components/atoms/Typography/Typography'
import { HStack } from 'src/components/templates/HStack'

import { useGetAdminApplicationQuery } from 'src/config/generated/graphql'

export interface SubmitApplicationModalProps {
  closeModal: () => void
  onSubmit?: () => void
  applicationId?: string
}

export const SubmitApplicationModal = ({
  closeModal,
  onSubmit,
  applicationId
}: SubmitApplicationModalProps) => {
  const { data: applicationData } = useGetAdminApplicationQuery({
    variables: {
      id: applicationId
    }
  })
  const handleSubmit = () => {
    if (onSubmit) onSubmit()

    closeModal()
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3 className='mb-5'>
        Submit tree request #{applicationData?.Application?.Reference}
      </Heading3>
      <Heading5 className='mb-5'>{applicationData?.Application?.Name}</Heading5>
      <Paragraph>
        Once you submit, it will go into pending approval and you will no longer
        be able to make changes to this tree request.
      </Paragraph>
      <Paragraph>Are you sure you want to submit this tree request?</Paragraph>
      <Divider />
      <HStack className='mt-10 flex justify-between'>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Cancel
        </Button>
        <Button className='mr-2' onClick={handleSubmit} type='submit'>
          Yes, submit tree request
        </Button>
      </HStack>
    </div>
  )
}
