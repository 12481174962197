import { useRouter } from 'next/router'
import { useState } from 'react'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { Radio, RadioGroup } from 'src/components/atoms/RadioGroup'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HSpaceBetweenStack } from 'src/components/templates/HStack'

import { homePath } from 'src/config/paths'

export interface SaveAsDraftModalProps {
  closeModal: () => void
  onSaveAsDraft?: () => Promise<void>
  onCancel?: () => void
  destination?: string
  prompt: string
}

export const SaveAsDraftModal = ({
  closeModal,
  destination,
  onCancel,
  onSaveAsDraft,
  prompt
}: SaveAsDraftModalProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>()
  const router = useRouter()

  const handleCancel = async () => {
    if (selectedValue === 'save' && onSaveAsDraft) {
      await onSaveAsDraft()
    }
    if (selectedValue === 'discard' && onCancel) onCancel()

    closeModal()

    router.push(destination || homePath)
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Are you sure you want to cancel?</Heading3>

      <Paragraph>{prompt}</Paragraph>
      <div className='my-6'>
        <RadioGroup id='saveAsDraft'>
          <Radio
            checked={selectedValue === 'save'}
            id='saveAsDraft'
            label="Save as draft. I'll complete it later."
            onChange={() => setSelectedValue('save')}
            value='save'
          />
          <Radio
            checked={selectedValue === 'discard'}
            id='saveAsDraft'
            label="Don't save any information. Discard what I've added."
            onChange={() => setSelectedValue('discard')}
            value='discard'
          />
        </RadioGroup>
      </div>

      <Divider />

      <HSpaceBetweenStack>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Close
        </Button>
        <Button
          className='mr-2'
          onClick={handleCancel}
          type='submit'
          disabled={!selectedValue}
        >
          Confirm
        </Button>
      </HSpaceBetweenStack>
    </div>
  )
}
