export interface Props {
  when: any
  children: JSX.Element | string | null | (JSX.Element | string | null)[]
  fallback?: JSX.Element
}

export const Show = ({ when, children, fallback }: Props) => {
  if (when) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  if (fallback) {
    return fallback
  }

  return null
}
