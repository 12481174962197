import { useRouter } from 'next/router'

import { useUser } from 'src/providers/UserProvider'

import { useGetRegistryViewQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const ViewRegistryBreadcrumb = () => {
  const router = useRouter()
  const { user } = useUser()

  const { registryId } = router.query as { registryId: string }

  const { data: registryData } = useGetRegistryViewQuery({
    variables: { id: registryId, funderId: user?.Account?.FunderId }
  })

  const name = registryData?.RegistryView[0]?.Name || 'Tree registry'
  const breadcrumbText = truncateBreadcrumbText(name)

  return <span>{breadcrumbText}</span>
}
