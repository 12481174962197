import { useRouter } from 'next/router'

import { useGetRegistryByUriQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const ViewRegistryByUriBreadcrumb = () => {
  const router = useRouter()

  const { registryUri } = router.query as {
    registryUri: string
  }

  const { data: registryData } = useGetRegistryByUriQuery({
    variables: { uri: registryUri }
  })

  const name = registryData?.Registry[0]?.Name
  const breadcrumbText = name ? truncateBreadcrumbText(name) : 'Tree registry'

  return <span>{breadcrumbText}</span>
}
