import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import { isAdmin } from 'src/utils/admin'

import { Breadcrumb } from './breadcrumbs.types'
import { customBreadcrumbs } from './customBreadcrumbs'
import { excludedPaths } from './excludedPaths'

export interface BreadcrumbItemProps extends Breadcrumb {
  active: boolean
  disableHref?: boolean
}

const BreadcrumbItem = ({
  active,
  breadcrumb,
  href,
  disableHref
}: BreadcrumbItemProps) => {
  return (
    <li
      className={`list-outside ${
        active ? '' : 'after:content-[">"] after:ml-2 after:mr-2'
      }`}
    >
      {active || disableHref ? (
        <span>{breadcrumb}</span>
      ) : (
        <Link href={href} passHref>
          {breadcrumb}
        </Link>
      )}
    </li>
  )
}

export const Breadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  const router = useRouter()

  useEffect(() => {
    // SG TODO: most of this splitting/shifting/filtering/remapping etc.
    // is not required, can we simplify?
    // NOTE: we track both a path and href so that we can match on the correct path (pre string interpolation) and build a link using resource IDs (if applicable)
    const pathWithoutQuery = router.asPath.split('?')[0]
    const pathnameWithoutQuery = router.pathname.split('?')[0]
    let pathArray = pathWithoutQuery?.split('/') || []
    let pathnameArray = pathnameWithoutQuery?.split('/') || []

    pathArray.shift()
    pathnameArray.shift()

    pathArray = pathArray.filter(path => {
      return path !== ''
    })
    pathnameArray = pathnameArray.filter(path => {
      return path !== ''
    })

    const currentBreadcrumbs = pathArray.reduce(
      (breadcrumbsArray: Breadcrumb[], pathString: string, index: number) => {
        const isExcludedPath = excludedPaths.find(ep => ep === `/${pathString}`)

        if (isExcludedPath) return breadcrumbsArray

        const path = `/${pathnameArray.slice(0, index + 1).join('/')}`
        const href = `/${pathArray.slice(0, index + 1).join('/')}`
        const customBreadcrumb = customBreadcrumbs.find(
          (cb: Omit<Breadcrumb, 'href'>) => {
            return cb.path === path
          }
        )

        return [
          ...breadcrumbsArray,
          {
            path,
            href,
            disableHref: customBreadcrumb?.disableHref,
            breadcrumb:
              customBreadcrumb?.breadcrumb ||
              pathString.charAt(0).toUpperCase() + pathString.slice(1)
          }
        ]
      },
      []
    )

    setBreadcrumbs(currentBreadcrumbs)
  }, [router.asPath, router.pathname])

  const showBreadcrumbs = useMemo(() => breadcrumbs.length > 1, [breadcrumbs])

  const buildContainerClasses = () => {
    if (isAdmin(router.pathname)) return `wide-container`

    return `container`
  }

  if (!showBreadcrumbs) return null

  return (
    <div className={`${buildContainerClasses()} pt-4 mx-auto px-3 text-shark`}>
      <nav aria-label='breadcrumbs'>
        <ol className='flex flex-row text-sm flex-wrap ml-2'>
          {breadcrumbs.map((crumb: Breadcrumb, index: number) => {
            const active = index === breadcrumbs.length - 1

            return (
              <BreadcrumbItem
                {...crumb}
                key={crumb.href}
                active={active}
                disableHref={crumb.disableHref}
              />
            )
          })}
        </ol>
      </nav>
    </div>
  )
}
