import { useRouter } from 'next/router'

import { useGetAdminBulkGiftsQuery } from 'src/config/generated/graphql'
import { formatDate } from 'src/utils/date'

export const AdminViewBulkBreadcrumb = () => {
  const router = useRouter()

  // Note: the breadcrumb matches on the spread blog post path /blog/[...slug]
  // so the slug param is an array rather than a string
  const { id } = router.query as {
    id: string[]
  }
  const { data } = useGetAdminBulkGiftsQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network'
  })

  const name = data?.Purchase_by_pk?.Funder.Account?.Name
  const date = data?.Purchase_by_pk?.CreatedOn

  return (
    <span>
      {name} -{' '}
      {formatDate({
        date: date as string,
        format: 'DD MMM YYYY',
        isUtc: true
      })}
    </span>
  )
}
