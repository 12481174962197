import { useRouter } from 'next/router'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HSpaceBetweenStack } from 'src/components/templates/HStack'

import { homePath } from 'src/config/paths'

export interface CancelModalProps {
  closeModal: () => void
  destination?: string
  prompt?: string
  onCancel?: () => void
}

export const CancelModal = ({
  closeModal,
  destination,
  prompt,
  onCancel
}: CancelModalProps) => {
  const router = useRouter()

  const handleCancel = () => {
    if (onCancel) onCancel()
    closeModal()
    router.push(destination || homePath)
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Are you sure you want to cancel?</Heading3>

      <Paragraph>
        {prompt || <>You&apos;ll be taken back to the homepage.</>}
      </Paragraph>
      <HSpaceBetweenStack>
        <Button className='mr-2 w-40' onClick={closeModal} variant='outlined'>
          No
        </Button>
        <Button className='mr-2 w-40' onClick={handleCancel} type='submit'>
          Yes
        </Button>
      </HSpaceBetweenStack>
    </div>
  )
}
