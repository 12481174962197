import { useRouter } from 'next/router'

import { trpc } from 'src/utils/trpc'

import { truncateBreadcrumbText } from '../helper'

export const ViewAccountByUriBreadcrumb = () => {
  const router = useRouter()

  const { uri } = router.query as {
    uri: string
  }
  const { data: accountData } = trpc.useQuery(['accounts.getByUri', { uri }])

  const name = accountData?.account.Name
  const breadcrumbText = name ? truncateBreadcrumbText(name) : 'Profile'

  return <span>{breadcrumbText}</span>
}
