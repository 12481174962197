import * as Yup from 'yup'

import { APPROVED_TREE_MIN } from 'src/types/application'

export default Yup.object().shape({
  Approved: Yup.number()
    .min(
      APPROVED_TREE_MIN,
      `The minimum number of trees to be approved is ${APPROVED_TREE_MIN}`
    )
    .required('Please enter a maximum number of trees for approval')
})
