import { useEffect } from 'react'

import { OnClickLink } from 'src/components/atoms/Link'

import { LocalAsset, S3Asset } from 'src/types/form'

import { StyledPreviewsContainer } from './StyledPreviewsContainer'
import {
  StyledThumbnail,
  StyledThumbnailContainer,
  StyledThumbnailImage,
  StyledThumbnailInner
} from './StyledThumbnail'

const defaultFallback = null

export interface ThumbnailsProps {
  files: (S3Asset | LocalAsset | undefined)[]
  onRemove?: (index: number) => void
  fallback?: JSX.Element | null
}

export const Thumbnails = ({
  files,
  onRemove,
  fallback = defaultFallback
}: ThumbnailsProps) => {
  useEffect(() => {
    // Revoke all data uris on component unmount
    return () => files.forEach(f => f && URL.revokeObjectURL(f.Uri))
  }, [files])

  return files.length ? (
    <StyledPreviewsContainer>
      {files.map((f, i: number) => {
        if (f === undefined) {
          return fallback
        }

        return (
          <StyledThumbnailContainer key={`${f.name} - ${i}`}>
            <StyledThumbnail>
              <StyledThumbnailInner>
                <StyledThumbnailImage
                  alt={`Thumbnail for file: ${f.name}`}
                  src={f.Uri}
                  width={144}
                  height={144}
                  className='object-cover'
                />
              </StyledThumbnailInner>
            </StyledThumbnail>
            {onRemove ? (
              <OnClickLink className='text-center' onClick={() => onRemove(i)}>
                Remove
              </OnClickLink>
            ) : null}
          </StyledThumbnailContainer>
        )
      })}
    </StyledPreviewsContainer>
  ) : null
}
