import { IconProps } from './types'

export const Transactions = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 7C8 6.44772 8.44772 6 9 6H16C16.5523 6 17 6.44772 17 7C17 7.55228 16.5523 8 16 8H9C8.44772 8 8 7.55228 8 7Z'
        fill='currentColor'
      />
      <path
        d='M18.8456 6.67799C19.0515 6.85583 19.0515 7.14417 18.8456 7.32201L15.9002 9.86571C15.568 10.1526 15 9.94941 15 9.5437V4.4563C15 4.05059 15.568 3.84741 15.9002 4.13429L18.8456 6.67799Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 13C3 12.4477 3.44772 12 4 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H4C3.44772 14 3 13.5523 3 13Z'
        fill='currentColor'
      />
      <path
        d='M1.15444 13.322C0.948519 13.1442 0.948519 12.8558 1.15444 12.678L4.09984 10.1343C4.43202 9.84741 5 10.0506 5 10.4563L5 15.5437C5 15.9494 4.43202 16.1526 4.09984 15.8657L1.15444 13.322Z'
        fill='currentColor'
      />
    </svg>
  )
}
