import {
  GetApplicationQuery,
  GetAdminApplicationQuery,
  ListApplicationsQuery,
  ListAdminApplicationsViewQuery,
  GetAdminApplicationMatchesQuery,
  GetDashboardTabsDataQuery,
  GetProjectQuery,
  GetAdminProjectQuery,
  GetAdminApplicationByPlanterRegistrationIdQuery,
  ListAdminApplicationsByProjectIdQuery
} from 'src/config/generated/graphql'

import { ArrayElement } from './common'

// GQL types

export type AdminApplicationType = NonNullable<
  GetAdminApplicationQuery['Application'] & {
    WorkflowState: ApplicationWorkflowState
  }
>

export type AdminListApplicationViewType = ArrayElement<
  ListAdminApplicationsViewQuery['ApplicationView']
> & {
  WorkflowState: ApplicationWorkflowState
}

export type AdminApplicationMatchesType =
  GetAdminApplicationMatchesQuery['Application_by_pk']

export type AdminProjectApplicationType = ArrayElement<
  NonNullable<AdminApplicationType['Project']>['Applications']
> & {
  WorkflowState: ApplicationWorkflowState
}

export type ListApplicationType = ArrayElement<
  | ListApplicationsQuery['Application']
  | ArrayElement<GetProjectQuery['Project']>['Applications']
  | ArrayElement<GetAdminProjectQuery['Project']>['Applications']
  | ListAdminApplicationsByProjectIdQuery['Application']
>

export type ListDashboardApplicationType = ArrayElement<
  GetDashboardTabsDataQuery['Application']
>

export type ApplicationType = NonNullable<
  ArrayElement<GetApplicationQuery['Application']>
>

export type ProjectApplicationType = ArrayElement<
  NonNullable<ApplicationType['Project']>['Applications']
> & {
  WorkflowState: ApplicationWorkflowState
}

export type PlanterRegistrationApplicationType = ArrayElement<
  Omit<
    GetAdminApplicationByPlanterRegistrationIdQuery['Application'],
    'WorkflowState'
  >
> & {
  WorkflowState: ApplicationWorkflowState
}

// Frontend types and enums

export interface ApplicationListResponse {
  Name: string
  Id: string
  Season: number
  State: number
  Statename: string
}

export interface ApplicationFilter {
  label: string
  value: number
}

export enum ApplicationWorkflowState {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Planted = 'Planted',
  Monitoring = 'Monitoring',
  Declined = 'Declined',
  Withdrawn = 'Withdrawn'
}

export enum LandOwnershipOptions {
  Private = 'Private',
  DoC = 'Department of Conservation',
  Council = 'Council',
  Iwi = 'Iwi/Maori Trust',
  Other = 'Other'
}

export enum LandUseOptions {
  Schools = 'Schools/education centres',
  Dairy = 'Dairy farming',
  SheepCattle = 'Sheep and/or cattle farming',
  OtherAnimal = 'Other animal farming',
  Crops = 'Growing crops',
  OtherForestry = 'Other forestry',
  Residential = 'Residential',
  Lifestyle = 'Lifestyle',
  Other = 'Other'
}

export const userCompletedApplicationStates = [
  ApplicationWorkflowState.Approved,
  ApplicationWorkflowState.Monitoring,
  ApplicationWorkflowState.Planted
]

export const APPLICATION_TREE_MIN = 500
export const APPROVED_TREE_MIN = 500
export const APPLICATION_TREE_UPDATE_MIN = 250
export const APPROVED_TREE_UPDATE_MIN = 250
