import { useUser } from 'src/providers/UserProvider'

/**
 * This Loader component is making sure everything the app needs to function is available
 * - auth has finished
 * - user fetching has finished if required.
 *
 * It just so happens that the user loading flag encapsulates both of those things at the moment,
 * as it depends on the auth loading flag and kicks off a user fetching query if the user is logged in,
 * otherwise it just waits for auth to finish.
 */
export const Loader = ({ children }: { children: any }) => {
  const { isLoading } = useUser()

  return isLoading ? null : children
}
