import { useUser } from 'src/providers/UserProvider'

import { Image } from 'src/components/atoms/Image'
import { OnClickLink } from 'src/components/atoms/Link'
import { Show } from 'src/components/atoms/Show'
import { MenuSectionTitle, Paragraph } from 'src/components/atoms/Typography'

import { env } from 'src/env/client.mjs'

export type AccountSwitcherProps = {
  onAccountClick?: () => void
}

export const AccountSwitcher = ({ onAccountClick }: AccountSwitcherProps) => {
  const { user, memberships, updateActiveAccountId } = useUser()

  const filteredMemberships =
    memberships?.filter(up => up.AccountId !== user?.ActiveAccountId) || []

  const handleSwitchActiveAccount = (id: string) => () => {
    if (user?.Id && id !== user?.ActiveAccountId) {
      updateActiveAccountId({ variables: { ActiveAccountId: id, id: user.Id } })
      if (onAccountClick) {
        onAccountClick()
      }
    }
  }

  return (
    <Show when={filteredMemberships?.length}>
      <MenuSectionTitle className='text-osloGrey my-[10px]'>
        Switch account
      </MenuSectionTitle>
      <div className='flex flex-col overflow-hidden overflow-y-auto'>
        {filteredMemberships.map(up => (
          <OnClickLink
            color='black'
            key={up.AccountId}
            className='flex items-center'
            onClick={handleSwitchActiveAccount(up.AccountId)}
          >
            <div className='flex space-x-4 items-center py-2'>
              <div className='shrink-0'>
                <div className='rounded-full relative border w-10 h-10 overflow-hidden'>
                  <Image
                    alt={`${up.Account.Name} profile picture`}
                    className='object-cover'
                    src={`${env.NEXT_PUBLIC_ASSETS_DOMAIN}/${up.Account.Avatar}`}
                    fill
                  />
                </div>
              </div>
              <Paragraph className='!mb-0 !text-[16px] font-medium'>
                {up.Account.Name}
              </Paragraph>
            </div>
          </OnClickLink>
        ))}
      </div>
    </Show>
  )
}
