import { useEffect, useRef } from 'react'

export interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  id: string
  maxCharacters?: number
  resize?: boolean
  autoResize?: boolean
}

export const TextArea = ({
  className = '',
  disabled,
  id,
  maxCharacters,
  onBlur,
  onChange,
  placeholder,
  value,
  rows,
  resize,
  autoResize
}: TextAreaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const resizeTextArea = () => {
      textAreaRef.current!.style.height = ''
      textAreaRef.current!.style.height = `${Math.min(
        textAreaRef.current!.scrollHeight,
        300
      )}px`
    }

    if (autoResize && textAreaRef.current) {
      const internalRef = textAreaRef
      internalRef.current?.addEventListener('input', resizeTextArea)
      resizeTextArea()
      return () => {
        internalRef.current?.removeEventListener('input', resizeTextArea)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (maxCharacters) {
      const { value: val } = e.target
      if (val.length > maxCharacters) {
        e.target.value = val.slice(0, maxCharacters)
      }
    }

    if (onChange) {
      onChange(e)
    }
  }

  return (
    <>
      <div
        className={`form-input-wrapper ${
          disabled ? 'form-input-wrapper-disabled' : ''
        } ${className}`}
      >
        <textarea
          ref={textAreaRef}
          className={`ttc-form-input min-h-[100px] ${
            resize && !autoResize ? '' : 'resize-none'
          }`}
          disabled={disabled}
          id={id}
          name={id}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder={placeholder}
          rows={rows}
          value={value}
        />
      </div>
      {maxCharacters ? (
        <span>
          {(value as string)?.length || 0} / {maxCharacters}
        </span>
      ) : null}
    </>
  )
}
