import { Form, Formik } from 'formik'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3 } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useUpdateProjectMutation
} from 'src/config/generated/graphql'
import { FormFieldTypes } from 'src/types/form'

import { UpdateProjectVisibilityFormValues } from './types'
import validationSchema from './validationSchema'

export interface UpdateProjectVisibilityModalProps {
  projectId?: string
  publicPage?: boolean
  closeModal: () => void
}

export const UpdateProjectVisibilityModal = ({
  projectId,
  publicPage,
  closeModal
}: UpdateProjectVisibilityModalProps) => {
  const [updateProjectVisibility] = useUpdateProjectMutation({
    onCompleted: () =>
      notify({
        message: 'Successfully updated project visibility',
        type: NotificationType.success
      })
  })

  const handleUpdateProjectVisibility = async (
    values: UpdateProjectVisibilityFormValues
  ) => {
    if (projectId) {
      await updateProjectVisibility({
        variables: {
          projectId,
          input: {
            Id: projectId,
            PublicPage: values.PublicPage
          }
        },
        refetchQueries: [
          namedOperations.Query.getAdminProject,
          namedOperations.Query.getProjectNavState
        ],
        onCompleted: closeModal
      })
    }
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Formik
        enableReinitialize
        initialValues={{
          PublicPage: !!publicPage
        }}
        onSubmit={handleUpdateProjectVisibility}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <Form>
              <Heading3 className='mb-5'>Update website visibility</Heading3>

              <FormField
                id='PublicPage'
                label=''
                className='!mb-0'
                type={FormFieldTypes.radioGroup}
                options={[
                  {
                    label: 'Show on the website',
                    selectedContent:
                      'It will appear on the projects list page and searchable by search engines.',
                    value: 'true'
                  },
                  {
                    label: 'Hide project page from the website',
                    selectedContent:
                      'This will also hide it from search engines.',
                    value: 'false'
                  }
                ]}
              />

              <HStack className='mt-10 flex justify-between'>
                <Button
                  className='mr-2'
                  onClick={closeModal}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || !dirty}
                  className='mr-2'
                  type='submit'
                >
                  Save changes
                </Button>
              </HStack>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
