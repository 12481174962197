import { IconProps } from './types'

export const SupporterToolkit = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 3.75C6 3.02065 6.28973 2.32118 6.80546 1.80546C7.32118 1.28973 8.02065 1 8.75 1H11.25C11.9793 1 12.6788 1.28973 13.1945 1.80546C13.7103 2.32118 14 3.02065 14 3.75V4.193C14.572 4.248 15.14 4.315 15.706 4.393C17.053 4.582 18 5.75 18 7.07V10.539C18 11.665 17.306 12.73 16.17 13.079C14.218 13.678 12.146 14 10 14C7.854 14 5.781 13.678 3.83 13.079C2.694 12.73 2 11.665 2 10.539V7.07C2 5.749 2.947 4.581 4.294 4.394C4.8612 4.31511 5.42999 4.24809 6 4.193V3.75ZM12.5 3.75V4.075C10.8348 3.9748 9.16516 3.9748 7.5 4.075V3.75C7.5 3.06 8.06 2.5 8.75 2.5H11.25C11.94 2.5 12.5 3.06 12.5 3.75ZM10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V11.01C9 11.2752 9.10536 11.5296 9.29289 11.7171C9.48043 11.9046 9.73478 12.01 10 12.01H10.01C10.2752 12.01 10.5296 11.9046 10.7171 11.7171C10.9046 11.5296 11.01 11.2752 11.01 11.01V11C11.01 10.7348 10.9046 10.4804 10.7171 10.2929C10.5296 10.1054 10.2752 10 10.01 10H10Z'
        fill='currentColor'
      />
      <path
        d='M3 15.0551V14.3711C3.126 14.4241 3.255 14.4711 3.39 14.5131C5.482 15.1551 7.703 15.5001 10 15.5001C12.297 15.5001 14.518 15.1551 16.61 14.5131C16.745 14.4721 16.874 14.4241 17 14.3711V15.0551C17 16.4021 16.015 17.5851 14.637 17.7411C11.5554 18.088 8.4446 18.088 5.363 17.7411C3.985 17.5851 3 16.4021 3 15.0551Z'
        fill='currentColor'
      />
    </svg>
  )
}
