import { ArrayHelpers, Field, FieldArray } from 'formik'
import { ChangeEvent } from 'react'
import { MdAddCircle, MdRemove } from 'react-icons/md'

import { Button } from 'src/components/atoms/Button'
import { DefaultInput } from 'src/components/atoms/DefaultInput'
import { IconButton } from 'src/components/atoms/IconButton'
import { Show } from 'src/components/atoms/Show'

export interface TextInputArrayProps {
  disabled?: boolean
  id: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value?: string[]
}

export const TextInputArray = ({
  disabled,
  id,
  onChange,
  placeholder,
  value
}: TextInputArrayProps) => {
  return (
    <FieldArray
      name={id}
      render={({ push, remove }: ArrayHelpers) => {
        return value?.map((item, index) => {
          return (
            <div key={index}>
              <div className='flex align-middle gap-2'>
                <Field
                  component={DefaultInput}
                  key={index}
                  id={`${id}[${index}]`}
                  placeholder={placeholder}
                  value={item}
                  onChange={onChange}
                  disabled={disabled}
                />

                <IconButton
                  icon={MdRemove}
                  disabled={index === 0 || disabled}
                  ariaLabel={`remove item ${index + 1}`}
                  className='mb-2 self-center'
                  color='primary'
                  onClick={() => remove(index)}
                />
              </div>
              {/* Only render add button after the last item */}
              <Show when={index === (value?.length || -1) - 1}>
                <Button onClick={() => push('')} disabled={disabled}>
                  <MdAddCircle className='mr-2' size='24px' />
                  Add another
                </Button>
              </Show>
            </div>
          )
        })
      }}
    />
  )
}
