import { MdDownload } from 'react-icons/md'

import { ExternalLink, OnClickLink } from 'src/components/atoms/Link'
import { HSpaceBetweenStack } from 'src/components/templates/HStack'

import { LocalAsset, S3Asset } from 'src/types/form'

export interface FilesListProps {
  className?: string
  files: (S3Asset | LocalAsset)[]
  onRemove?: (index: number) => void
  disableDownload?: boolean
}

export const FilesList = ({
  className = '',
  files,
  onRemove,
  disableDownload
}: FilesListProps) => {
  return (
    <ul className={className}>
      {files.map((f, i: number) => (
        <li key={`${f.name || f.FileName} - ${i}`} className='px-0 py-2'>
          <HSpaceBetweenStack className='max-w-lg'>
            {disableDownload ? (
              <span className='font-medium'>{f.name || f.FileName}</span>
            ) : (
              <ExternalLink
                download={f.name}
                className='text-center text-shark'
                href={f.Uri}
                target='_blank'
              >
                <MdDownload className='mr-1 inline' size='24px' />
                {f.name || f.FileName}
              </ExternalLink>
            )}
            {onRemove ? (
              <OnClickLink
                className='text-center text-shark'
                onClick={() => onRemove(i)}
              >
                Remove
              </OnClickLink>
            ) : null}
          </HSpaceBetweenStack>
        </li>
      ))}
    </ul>
  )
}
