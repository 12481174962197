import { useMemo } from 'react'

import { useAuth } from 'src/providers/AuthProvider'

import { EmailVerificationBanner } from './EmailVerificationBanner'

export const Banner = () => {
  const { awaitingEmailVerification } = useAuth()

  // Banner conditions can be added here in the future when we have more than one banner
  const showBanner = useMemo(
    () => awaitingEmailVerification,
    [awaitingEmailVerification]
  )

  // Banner content can be determined here in the future when we have more than one banner
  const BannerContent = useMemo(() => {
    if (awaitingEmailVerification) {
      return <EmailVerificationBanner />
    }
  }, [awaitingEmailVerification])

  return showBanner ? (
    <div className='flex flex-col w-full box-border flex-shrink-0 static bg-primary text-white shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.2),0px_4px_5px_0px_rgba(0,0,0,0.14),0px_1px_10px_0px_rgba(0,0,0,0.12)]'>
      <div className='relative flex items-center justify-center px-8 min-h-[48px] sm:px-12'>
        {BannerContent}
      </div>
    </div>
  ) : null
}
