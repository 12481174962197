export function padTo4Digits(num: number) {
  return num.toString().padStart(4, '0')
}

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

export function dateToInputValues(date: Date | undefined) {
  if (!date || !(date instanceof Date) || date.toString() === 'Invalid Date') {
    return {
      date: '',
      time: ''
    }
  }

  const formattedDate = [
    padTo4Digits(date.getFullYear()),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate())
  ].join('-')

  const formattedTime = [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes())
  ].join(':')

  return {
    date: formattedDate,
    time: formattedTime
  }
}

export function inputValuesToDate(date: string, time: string) {
  // date inputs allow for a 6 digit year but the built-in Date Object only allows a 4 digit date
  const yearLength = date.split('-')[0]?.length || 4
  const safeDate =
    yearLength > 4 ? `${date.slice(0, 4)}${date.slice(yearLength)}` : date

  return new Date(`${safeDate}T${time}`)
}
