import { useMemo } from 'react'

import { Select, SelectProps } from 'src/components/atoms/Select'

import { useListRegionsAndDistrictsQuery } from 'src/config/generated/graphql'
import { Option } from 'src/types/form'

import { buildDistrictsOptions } from './helper'

export interface DistrictSelectProps extends SelectProps {
  showNullOption?: boolean
  regionId?: string
}

export const DistrictSelect = ({ regionId, ...props }: DistrictSelectProps) => {
  const { data: regionsAndDistrictsData } = useListRegionsAndDistrictsQuery()

  const options: Option[] = useMemo(
    () => buildDistrictsOptions(regionsAndDistrictsData?.District, regionId),
    [regionsAndDistrictsData, regionId]
  )

  return <Select {...props} options={options} />
}
