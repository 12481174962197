import * as Yup from 'yup'

import {
  APPROVED_TREE_UPDATE_MIN,
  APPLICATION_TREE_UPDATE_MIN,
  ApplicationWorkflowState
} from 'src/types/application'

const validationSchema = Yup.object({
  CurrentApproved: Yup.number(),
  CurrentRequired: Yup.number(),
  Approved: Yup.number()
    .when('WorkflowState', {
      is: ApplicationWorkflowState.Pending,
      then: Yup.number(),
      otherwise: Yup.number().min(
        APPROVED_TREE_UPDATE_MIN,
        `The minimum number of trees to be approved is ${APPROVED_TREE_UPDATE_MIN}`
      )
    })
    .test(
      'ApprovedMatched',
      "Approved can't be less than the number of trees already matched",
      (val, context) => {
        return (
          context.parent.WorkflowState === ApplicationWorkflowState.Pending ||
          (!!val && val >= context.parent.Matched)
        )
      }
    ),
  Required: Yup.number()
    .min(
      APPLICATION_TREE_UPDATE_MIN,
      `Planters must apply for a minimum of ${APPLICATION_TREE_UPDATE_MIN} trees`
    )
    .test(
      'RequiredMatched',
      "Required can't be less than the number of trees already matched",
      (val, context) => {
        return !!val && val >= context.parent.Matched
      }
    ),
  Notes: Yup.string().required('Please add a reason for changing tree numbers'),
  Matched: Yup.number(),
  WorkflowState: Yup.string()
})

export type UpdateApplicationCountsValues = Yup.InferType<
  typeof validationSchema
>

export default validationSchema
