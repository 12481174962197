import * as Yup from 'yup'

export default Yup.object({
  Reason: Yup.string().required(
    'Please select a reason for deleting your project'
  ),
  Confirm: Yup.boolean()
    .oneOf([true])
    .required('Please confirm if you want to delete your project')
})
