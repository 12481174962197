import {
  FormFieldTypes,
  InputAdornment,
  InputAdornmentType
} from 'src/types/form'

export interface DefaultInputProps extends React.HTMLProps<HTMLInputElement> {
  id: string
  inputAdornment?: InputAdornment
  type: FormFieldTypes
}

export const DefaultInput = ({
  className = '',
  disabled,
  id,
  inputAdornment,
  max,
  min,
  onBlur,
  onChange,
  onKeyDown,
  placeholder,
  step,
  type,
  value
}: DefaultInputProps) => {
  return (
    <div
      className={`form-input-wrapper ${
        disabled ? 'form-input-wrapper-disabled' : ''
      } ${className}`}
    >
      {inputAdornment?.type === InputAdornmentType.start ? (
        <div
          className='ml-0 pr-2 form-input-adornment'
          title={inputAdornment.title}
        >
          {inputAdornment.content}
        </div>
      ) : null}
      <input
        className='ttc-form-input'
        disabled={disabled}
        id={id}
        max={max}
        min={min}
        name={id}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onWheelCapture={e => {
          if (type === FormFieldTypes.number) e.currentTarget.blur()
        }}
        placeholder={placeholder}
        step={step}
        type={type}
        value={
          type === FormFieldTypes.number ? Number(value).toString() : value
        }
      />
      {inputAdornment?.type === InputAdornmentType.end ? (
        <div className='form-input-adornment' title={inputAdornment.title}>
          {inputAdornment.content}
        </div>
      ) : null}
    </div>
  )
}
