import { IconProps } from './types'

export const GridView = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5625 0H19.0625C19.5803 0 20 0.479688 20 1.07143V8.21429C20 8.80603 19.5803 9.28571 19.0625 9.28571H11.5625C11.0447 9.28571 10.625 8.80603 10.625 8.21429V1.07143C10.625 0.479688 11.0447 0 11.5625 0ZM8.4375 0H0.9375C0.419727 0 0 0.479688 0 1.07143V8.21429C0 8.80603 0.419727 9.28571 0.9375 9.28571H8.4375C8.95527 9.28571 9.375 8.80603 9.375 8.21429V1.07143C9.375 0.479688 8.95527 0 8.4375 0ZM0 11.7857V18.9286C0 19.5203 0.419727 20 0.9375 20H8.4375C8.95527 20 9.375 19.5203 9.375 18.9286V11.7857C9.375 11.194 8.95527 10.7143 8.4375 10.7143H0.9375C0.419727 10.7143 0 11.194 0 11.7857ZM11.5625 20H19.0625C19.5803 20 20 19.5203 20 18.9286V11.7857C20 11.194 19.5803 10.7143 19.0625 10.7143H11.5625C11.0447 10.7143 10.625 11.194 10.625 11.7857V18.9286C10.625 19.5203 11.0447 20 11.5625 20Z'
        fill='currentColor'
      />
    </svg>
  )
}
