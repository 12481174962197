import { useRouter } from 'next/router'

import { trpc } from 'src/utils/trpc'

export const ViewBlogPostBreadcrumb = () => {
  const router = useRouter()

  // Note: the breadcrumb matches on the spread blog post path /blog/[...slug]
  // so the slug param is an array rather than a string
  const { slug } = router.query as {
    slug: string[]
  }
  const { data: post } = trpc.useQuery([
    'sanity.getPostBySlug',
    { slug: slug[0]! }
  ])

  const name = post?.title
  const breadcrumbText = name || 'Post'

  return <span>{breadcrumbText}</span>
}
