import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextMarkComponentProps,
  PortableTextProps,
  PortableTextReactComponents
} from '@portabletext/react'

import { ExternalLink } from 'src/components/atoms/Link'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Paragraph
} from 'src/components/atoms/Typography'
import { CallOut } from 'src/components/atoms/Typography/Typography'

import { Body } from 'src/config/generated/sanitySchema'

import { CtaBlock } from './CtaBlock'
import { Blockquote } from './blocks/Blockquote'
import { Image } from './blocks/Image'
import { InternalRefBlock } from './blocks/InternalRefBlock'
import { Link } from './blocks/Link'
import { Number, Bullet } from './blocks/List'

export type TextProps = PortableTextProps
export type TextValue = TextProps['value']

const defaultComponents: Partial<PortableTextReactComponents> = {
  marks: {
    link: Link,
    internalRefBlock: InternalRefBlock
  },
  block: {
    // @ts-ignore ... PortableText ts code is a bit whacky
    normal: Paragraph as PortableTextBlockComponent
  }
}

export const Text = ({
  components = defaultComponents,
  ...rest
}: TextProps) => {
  return <PortableText components={components} {...rest} />
}

const contentComponents = {
  types: {
    image: Image
  },
  marks: {
    link: ({
      children,
      value
    }: PortableTextMarkComponentProps<{ _type: 'link'; href: string }>) => {
      return <ExternalLink href={value?.href || ''}>{children}</ExternalLink>
    },
    ctaBlock: CtaBlock
  },
  block: {
    // @ts-ignore ... PortableText ts code is a bit whacky
    normal: Paragraph as PortableTextBlockComponent,
    blockquote: Blockquote,
    // @ts-ignore
    h1: Heading1 as PortableTextBlockComponent,
    // @ts-ignore
    h2: Heading2 as PortableTextBlockComponent,
    // @ts-ignore
    h3: Heading3 as PortableTextBlockComponent,
    // @ts-ignore
    h4: Heading4 as PortableTextBlockComponent,
    // @ts-ignore
    callout: CallOut as PortableTextBlockComponent
  },
  list: {
    number: Number,
    bullet: Bullet
  }
}

export const Content = ({ body }: { body: Body }) => {
  return <Text components={contentComponents} value={body} />
}
