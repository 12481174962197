import * as Yup from 'yup'

const validationSchema = Yup.object({
  Reason: Yup.string().required(),
  Confirm: Yup.boolean().oneOf([true])
})

export type RemoveMembershipFormValues = Yup.InferType<typeof validationSchema>

export default validationSchema
