import * as Yup from 'yup'

const validationSchema = Yup.object({
  Reason: Yup.string()
    .max(150, 'Reason should not be more than 150 characters')
    .required('Please provide a reason for cancelling')
})

export type CancelSubscriptionFormValues = Yup.InferType<
  typeof validationSchema
>

export default validationSchema
