import { useRouter } from 'next/router'

import { useGetMonitoringEventQuery } from 'src/config/generated/graphql'
import { formatDate } from 'src/utils/date'

export const MonitoringEventBreadcrumb = () => {
  const router = useRouter()
  const { eventId } = router.query as { eventId: string }

  const { data } = useGetMonitoringEventQuery({
    variables: { id: eventId }
  })

  const monitoringDate = data?.MonitoringEvent[0]?.MonitoredOn

  if (!monitoringDate) {
    return <span>{eventId}</span>
  }

  return (
    <span>
      {formatDate({
        date: monitoringDate,
        format: 'DD MMM YYYY',
        isUtc: true
      }) || eventId}
    </span>
  )
}
