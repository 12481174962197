import React, { useEffect, useMemo, useState } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { MdFileUpload } from 'react-icons/md'

import { Button, ButtonProps } from 'src/components/atoms/Button'
import { ParagraphSmaller } from 'src/components/atoms/Typography'
import { FilesList } from 'src/components/molecules/FilesList'
import { Thumbnails } from 'src/components/molecules/Thumbnails'

import { usePrevious } from 'src/hooks/usePrevious'
import { LocalAsset, S3Asset } from 'src/types/form'

import { StyledDropzone } from './StyledDropzone'
import { StyledDropzoneContainer } from './StyledDropzoneContainer'

export enum FileType {
  document = 'document',
  image = 'image',
  csv = 'csv'
}

const MAX_FILE_BYTES = 7500000

export interface FileUploadProps {
  dropzoneText?: string
  fileType?: FileType
  onFileChange?: (files: (LocalAsset | S3Asset)[]) => void
  renderDropzone?: boolean
  renderFilesAsList?: boolean
  renderFilesList?: boolean
  replaceFiles?: boolean
  maxFiles?: number
  uploadButtonText?: string
  uploadButtonColor?: ButtonProps['color']
  uploadButton?: JSX.Element
  value?: S3Asset[]
  id: string
  previewElement?: JSX.Element
}

export const FileUpload = ({
  dropzoneText = `Drop your photos here, or click 'Upload photos' to browse and select from
  your computer.`,
  fileType = FileType.image,
  onFileChange,
  renderDropzone = true,
  renderFilesAsList = true,
  renderFilesList = true,
  maxFiles,
  replaceFiles,
  uploadButtonText = 'Upload photos',
  uploadButtonColor = 'tertiary',
  uploadButton,
  value,
  id,
  previewElement
}: FileUploadProps) => {
  const [files, setFiles] = useState<(LocalAsset | S3Asset)[]>(
    (value || [])
      .filter(v => !!v)
      .map((file: File) =>
        Object.assign(file, {
          Uri: URL.createObjectURL(file)
        })
      )
  )

  const prevValue = usePrevious(value)
  /**
   * Without checking against `prevValue`, e.g. in the code block below,
   * we get an error like so in the console when navigating between steps after
   * having uploaded or deleted a file:
   *
   * ```
   * useEffect(() => {
   *  if (value) {
   *    setFiles(value)
   *  }
   * }, [value])
   * ```
   *
   * Security Error: Content at http://localhost:3000/auth-callback?code=6PWo1szYycQ4qhv2JAFE7UPqBTKmLMISMuSxw-REb4TZI&state=YmVlYlpILm1Dak4tZl9KdUprYXdEbWR2Wmo2cW9SWWd2VXQxWXluQmZKSg%3D%3D may not load data from blob:http://localhost:3000/65a1cb38-3e49-433d-bd0b-3cf1fde8747c.
   *
   * Initially it was causing a "maximum update depth exceeded" issue, which
   * prompted me to check against the previous value and implement the current
   * solution. But I believe I was only seeing that in my local development due
   * to the entire screen being reloaded due to hot reloading.
   *
   * Long story short... not 100% on why this works to fix the error shown
   * above, but without it we get some yucky red text in the console.
   */
  useEffect(() => {
    if (prevValue) {
      if (value && value !== prevValue) {
        setFiles(value)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, value])

  const accept = useMemo(() => {
    // See: https://react-dropzone.js.org/#section-accepting-specific-file-types
    switch (fileType) {
      case FileType.image:
        return {
          'image/*': []
        }
      case FileType.document:
        return {
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            []
        }
      case FileType.csv:
        return { 'text/csv': [] }
      default:
        return {}
    }
  }, [fileType])

  const { fileRejections, getInputProps, getRootProps, open } = useDropzone({
    accept: accept as DropzoneOptions['accept'],
    maxSize: MAX_FILE_BYTES,
    maxFiles,
    noClick: true,
    onDrop: (acceptedFiles: File[]) => {
      if (replaceFiles) {
        if (maxFiles && acceptedFiles.length <= maxFiles)
          setFiles(
            acceptedFiles.map((file: File) =>
              Object.assign(file, { Uri: URL.createObjectURL(file) })
            ) as LocalAsset[]
          )
      }
      if (!maxFiles || files.length < maxFiles) {
        setFiles([
          ...files,
          ...(acceptedFiles.map((file: File) =>
            Object.assign(file, {
              Uri: URL.createObjectURL(file)
            })
          ) as LocalAsset[])
        ])
      }
    }
  })

  useEffect(() => {
    if (onFileChange) onFileChange(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const removeFile = (index: number) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const renderUploadButton = () => {
    if (uploadButton)
      return React.cloneElement(uploadButton, {
        onClick: open
      })

    return (
      <Button
        className='space-x-2 w-auto font-semibold text-base h-[46px]'
        color={uploadButtonColor}
        size='base'
        onClick={open}
      >
        <MdFileUpload size={18} />
        <span>{uploadButtonText}</span>
      </Button>
    )
  }

  const renderFiles = () => {
    if (renderFilesList) {
      return files.length ? (
        <FilesList
          className='py-2'
          disableDownload
          files={files}
          onRemove={removeFile}
        />
      ) : null
    }

    return null
  }

  const renderErrors = () =>
    fileRejections.length ? (
      <div className='mt-[10px]'>
        {fileRejections[0]?.errors?.map((error, i) => {
          let { message } = error
          if (error.code === 'file-too-large') {
            message = `Files should not be larger than ${
              MAX_FILE_BYTES / 1000000
            }MB`
          }
          return (
            <div key={i} className='form-error-message' role='alert'>
              Upload error: {message}
            </div>
          )
        })}
      </div>
    ) : null

  return (
    <div>
      {renderDropzone ? (
        <>
          <StyledDropzoneContainer>
            <StyledDropzone {...getRootProps()}>
              <input id={id} {...getInputProps()} />
              <ParagraphSmaller className='text-center my-1'>
                {dropzoneText}
              </ParagraphSmaller>
              {renderUploadButton()}
            </StyledDropzone>
          </StyledDropzoneContainer>
          <Thumbnails files={files} onRemove={removeFile} />
          {renderErrors()}
        </>
      ) : (
        <span {...getRootProps()} tabIndex={-1}>
          <input id={id} {...getInputProps()} />
          {previewElement ? (
            <div className='mb-[10px]'>{previewElement}</div>
          ) : null}
          {renderUploadButton()}

          {renderFilesAsList
            ? renderFiles()
            : renderFilesList && (
                <Thumbnails files={files} onRemove={removeFile} />
              )}
          {renderErrors()}
        </span>
      )}
    </div>
  )
}
