import { IconProps } from './types'

export const TreeRegistries = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.175 5.575C6.175 6.44406 5.46906 7.15 4.6 7.15C3.73009 7.15 3.025 6.44406 3.025 5.575C3.025 4.70509 3.73009 4 4.6 4C5.46906 4 6.175 4.70509 6.175 5.575ZM6.86125 8.62094C6.02594 9.36344 5.5 10.4463 5.5 11.65C5.5 12.6147 5.81219 13.5006 6.4 14.1953V14.8C6.4 15.2978 5.99781 15.7 5.5 15.7H3.7C3.20303 15.7 2.8 15.2978 2.8 14.8V14.0463C1.73575 13.54 1 12.4572 1 11.2C1 9.45906 2.41019 8.05 4.15 8.05H5.05C5.69969 8.05 6.34937 8.26094 6.86125 8.62094ZM2.8 10.0103C2.51987 10.3253 2.35 10.7444 2.35 11.2C2.35 11.6556 2.51987 12.0747 2.8 12.3897V10.0103ZM13.6 14.8V14.1953C14.1625 13.5006 14.5 12.6147 14.5 11.65C14.5 10.4463 13.9741 9.36344 13.1388 8.62094C13.6506 8.26094 14.275 8.05 14.95 8.05H15.85C17.5909 8.05 19 9.45906 19 11.2C19 12.4572 18.2631 13.54 17.2 14.0463V14.8C17.2 15.2978 16.7978 15.7 16.3 15.7H14.5C14.0022 15.7 13.6 15.2978 13.6 14.8ZM17.2 12.3897C17.4559 12.0747 17.65 11.6556 17.65 11.2C17.65 10.7444 17.4559 10.3253 17.2 10.0103V12.3897ZM16.975 5.575C16.975 6.44406 16.2691 7.15 15.4 7.15C14.5309 7.15 13.825 6.44406 13.825 5.575C13.825 4.70509 14.5309 4 15.4 4C16.2691 4 16.975 4.70509 16.975 5.575ZM8.2 5.8C8.2 4.80578 9.00719 4 10 4C10.9928 4 11.8 4.80578 11.8 5.8C11.8 6.79281 10.9928 7.6 10 7.6C9.00719 7.6 8.2 6.79281 8.2 5.8ZM13.6 11.65C13.6 12.9072 12.8631 13.99 11.8 14.4963V15.7C11.8 16.1978 11.3978 16.6 10.9 16.6H9.1C8.60219 16.6 8.2 16.1978 8.2 15.7V14.4963C7.13687 13.99 6.4 12.9072 6.4 11.65C6.4 9.90906 7.80906 8.5 9.55 8.5H10.45C12.1909 8.5 13.6 9.90906 13.6 11.65ZM8.2 12.8397V10.4603C7.91875 10.7753 7.75 11.1944 7.75 11.65C7.75 12.1056 7.91875 12.5247 8.2 12.8397ZM11.8 10.4603V12.8397C12.0559 12.5219 12.25 12.1056 12.25 11.65C12.25 11.1944 12.0559 10.7753 11.8 10.4603Z'
        fill='currentColor'
      />
    </svg>
  )
}
