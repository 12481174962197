import numeral from 'numeral'
import { useCallback } from 'react'
import { useIntercom } from 'react-use-intercom'

import { useUser } from 'src/providers/UserProvider'

import {
  GetUserAccountIntercomDataQuery,
  useGetUserAccountIntercomDataLazyQuery
} from 'src/config/generated/graphql'
import { env } from 'src/env/client.mjs'
import { formatDate } from 'src/utils/date'

const INTERCOM_ENABLED = env.NEXT_PUBLIC_INTERCOM_ENABLED

const transformIntercomUserData = (
  data: NonNullable<
    GetUserAccountIntercomDataQuery['get_user_account_intercom_data']
  >
) => {
  return {
    userHash: data.Hash!,
    name: `${data.FirstName} ${data.LastName}`,
    email: data.Email!,
    userId: data.ProfileId!,
    phone: data.PhoneNumber!,
    avatar: {
      type: 'avatar',
      imageUrl: `${env.NEXT_PUBLIC_ASSETS_DOMAIN}/${data.Avatar}`
    },
    createdAt: data.CreatedOn!,
    customAttributes: {
      // change to snake case
      admin_profile_link: data.AdminProfileLink,
      first_application_date: formatDate({
        date: data.ApplicationDate!,
        format: 'DD MMM YYYY'
      }),
      id_type: data.IdType,
      is_profile_hidden: data.IsProfileHidden ? 'Yes' : 'No',
      profile_link: data.ProfileLink,
      profile_name: data.ProfileName,
      profiles: data.Profiles?.map(p => ({
        name: p?.Name,
        profileLink: p?.ProfileLink,
        profileType: p?.ProfileType
      })),
      district: data.District,
      funded_amount_current_season: `$${numeral(data.FundedAmount).format(
        '0,0'
      )}`,
      funded_quantity_current_season: data.FundedQuantity,
      primary_category: data.PrimaryCategory,
      secondary_category: data.SecondaryCategory,
      region: data.Region,
      trees_applied_for_current_season: data.TreesAppliedFor,
      trees_approved_current_season: data.TreesApproved,
      last_funded: formatDate({
        date: data.LastFunded!,
        format: 'DD MMM YYYY'
      }),
      last_applied: formatDate({
        date: data.LastApplied!,
        format: 'DD MMM YYYY'
      }),
      trees_planted_last_season: data.TreesPlanted,
      has_subscription: data.HasSubscriptions ? 'Yes' : 'No',
      subscription_created_on: formatDate({
        date: data?.SubscriptionCreatedOn!,
        format: 'DD MMM YYYY'
      }),
      subscription_state: data.SubscriptionState,
      subscription_quantity: data.SubscriptionQuantity,
      subscription_cancelled_on: formatDate({
        date: data.SubscriptionCancelledOn!,
        format: 'DD MMM YYYY'
      })
    }
  }
}

export const useMessenger = () => {
  const { user, isLoading } = useUser()
  const { boot, shutdown, showNewMessage } = useIntercom()
  const [getUserProfileIntercomData] = useGetUserAccountIntercomDataLazyQuery()

  const bootMessenger = useCallback(
    (shouldShowNewMessage?: boolean) => {
      if (INTERCOM_ENABLED !== '1') return

      if (!user && !isLoading) {
        boot()
        if (shouldShowNewMessage) {
          showNewMessage()
        }
      } else if (user && user.Id && user.Account && !isLoading) {
        getUserProfileIntercomData({
          onCompleted: userIntercomData => {
            if (userIntercomData.get_user_account_intercom_data) {
              const data = transformIntercomUserData(
                userIntercomData.get_user_account_intercom_data
              )
              boot(data)
            } else {
              boot()
            }
            if (shouldShowNewMessage) {
              showNewMessage()
            }
          }
        })
      }
    },
    [boot, getUserProfileIntercomData, isLoading, showNewMessage, user]
  )

  const startNewMessage = useCallback(() => {
    bootMessenger(true)
  }, [bootMessenger])

  return {
    boot: bootMessenger,
    shutdown,
    showNewMessage: startNewMessage
  }
}
