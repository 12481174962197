import { Form, Formik } from 'formik'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useChangeUserMembershipTypeMutation
} from 'src/config/generated/graphql'
import { MembershipRoleType } from 'src/types/account'
import { FormFieldTypes } from 'src/types/form'

import validationSchema, { ChangeUserRoleFormValues } from './validationSchema'

type ChangeUserRoleModalProps = {
  closeModal: () => void
  membershipId?: string
  currentRole?: MembershipRoleType
}

const ChangeUserRoleModal = ({
  closeModal,
  membershipId,
  currentRole
}: ChangeUserRoleModalProps) => {
  const [changeUserMembershipType] = useChangeUserMembershipTypeMutation()

  const initialValues: ChangeUserRoleFormValues = {
    Role: currentRole || MembershipRoleType.Member
  }
  const handleUpdateRoleSubmit = async (values: ChangeUserRoleFormValues) => {
    changeUserMembershipType({
      variables: {
        input: {
          MembershipId: membershipId,
          Role: Number(values.Role)!
        }
      },
      refetchQueries: [namedOperations.Query.listAccountMembershipsAndInvites],
      onError: error => {
        notify({
          message: error.message,
          type: NotificationType.error,
          duration: 2000
        })
      },
      onCompleted: response => {
        if (response.change_user_membership_type?.MembershipId) {
          closeModal()
          notify({
            message: `You successfully changed the role for ${
              response.change_user_membership_type.UserEmail
            } from ${MembershipRoleType[currentRole!]} to ${
              MembershipRoleType[values.Role!]
            }`,
            type: NotificationType.success,
            duration: 2000
          })
        }
      }
    })
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3 className='!text-[28px] !leading-[39px] !font-semibold'>
        Change user role
      </Heading3>
      <Paragraph className='!text-[16px] !leading-[24px] !font-medium'>
        Select the type of role you want this user to have.
      </Paragraph>
      <Formik
        onSubmit={handleUpdateRoleSubmit}
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <FormField
              label=''
              type={FormFieldTypes.radioGroup}
              id='Role'
              options={[
                {
                  label: 'Member',
                  value: MembershipRoleType.Member.toString(),
                  selectedContent: (
                    <Paragraph>
                      Can view activities on this account, donate trees and
                      request trees.
                    </Paragraph>
                  ),
                  selectedContentAwlaysVisible: true
                },
                {
                  label: 'Owner',
                  value: MembershipRoleType.Owner.toString(),
                  selectedContent: (
                    <Paragraph>
                      Can add, remove other members, view activities on this
                      account, donate trees and request trees.
                    </Paragraph>
                  ),
                  selectedContentAwlaysVisible: true
                }
              ]}
            />
            <Divider />
            <HStack className='mt-10 flex justify-between'>
              <Button className='mr-2' onClick={closeModal} variant='outlined'>
                Cancel
              </Button>
              <Button
                className='mr-2'
                type='submit'
                disabled={isSubmitting || !isValid}
              >
                Save changes
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ChangeUserRoleModal
