import { useRouter } from 'next/router'

import { useGetAdminAccountQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const AdminViewAccountBreadcrumb = () => {
  const { accountId } = useRouter().query as { accountId: string }

  const { data: accountData } = useGetAdminAccountQuery({
    variables: { id: accountId }
  })

  const name = accountData?.Account?.Name
  const breadcrumbText = truncateBreadcrumbText(name || 'Profile')

  return <span>{breadcrumbText}</span>
}
