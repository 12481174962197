import {
  ArrayHelpers,
  ErrorMessage,
  Field,
  FieldArray,
  FormikContextType,
  useFormikContext,
  getIn
} from 'formik'
import { useEffect, useMemo } from 'react'

import { useFormLayout } from 'src/providers/FormLayoutProvider'

import { ButtonProps } from 'src/components/atoms/Button'
import { CardSwitch } from 'src/components/atoms/CardSwitch'
import { CardSwitchProps } from 'src/components/atoms/CardSwitch/CardSwitch'
import { DateTimePicker } from 'src/components/atoms/DateTimePicker'
import { DefaultInput } from 'src/components/atoms/DefaultInput'
import { Radio, RadioGroup } from 'src/components/atoms/RadioGroup'
import { Select } from 'src/components/atoms/Select'
import { TabSwitch } from 'src/components/atoms/TabSwitch'
import { TabSwitchProps } from 'src/components/atoms/TabSwitch/TabSwitch'
import { TextArea } from 'src/components/atoms/TextArea'
import { TextInputArray } from 'src/components/atoms/TextInputArray'
import {
  TreeQuantityRadio,
  TreeQuantityRadioGroup
} from 'src/components/atoms/TreeQuantityRadioGroup'
import { ParagraphSmaller } from 'src/components/atoms/Typography'
import { AccountSelect } from 'src/components/molecules/AccountSelect'
import { DistrictSelect } from 'src/components/molecules/DistrictSelect'
import { FileUpload, FileType } from 'src/components/molecules/FileUpload'
import { LabelledCheckbox } from 'src/components/molecules/LabelledCheckbox'
import { RegionSelect } from 'src/components/molecules/RegionSelect'
import { RegistrySelect } from 'src/components/molecules/RegistrySelect'

import { S3Asset, FormFieldTypes, InputAdornment, Option } from 'src/types/form'

import { RequiredIndicator } from '../RequiredIndicator'

export interface FormFieldProps {
  checkboxLabel?: string
  className?: string
  conditionForConditionalComponent?: boolean
  conditionalComponent?: JSX.Element
  conditionalComponentId?: string
  disabled?: boolean
  required?: boolean
  dropzoneText?: string
  fileType?: FileType
  formFieldClassName?: string
  description?: string | JSX.Element
  helpText?: string | JSX.Element
  id: string
  inputAdornment?: InputAdornment
  label: string | JSX.Element
  labelClassName?: string
  max?: number
  maxFiles?: number
  maxCharacters?: number
  min?: number
  nullOption?: Option
  onBlur?: (e: any) => void
  onChange?: (e: any) => void
  options?: Option[]
  placeholder?: string
  regionId?: string
  renderDropzone?: boolean
  renderFilesAsList?: boolean
  renderFilesList?: boolean
  replaceFiles?: boolean
  shouldParseInt?: boolean
  step?: number
  type: FormFieldTypes
  uploadButtonText?: string
  uploadButtonColor?: ButtonProps['color']
  uploadButton?: JSX.Element
  regionsOnly?: boolean
  rows?: number
  switchOptions?: TabSwitchProps['options'] | CardSwitchProps['options']
  resize?: boolean
  autoResize?: boolean
  previewElement?: JSX.Element
}

const FormControlWrapper = ({
  children,
  className = '',
  isRadioOrCheckboxGroup
}: {
  children: JSX.Element
  className?: string
  isRadioOrCheckboxGroup: boolean
}) => {
  const { margin } = useFormLayout()
  return isRadioOrCheckboxGroup ? (
    <fieldset
      className={`form-group ${margin === 'small' ? '!mb-4' : ''} ${className}`}
    >
      {children}
    </fieldset>
  ) : (
    <div
      className={`form-group ${margin === 'small' ? '!mb-4' : ''} ${className}`}
    >
      {children}
    </div>
  )
}

export const FormField = ({
  checkboxLabel,
  className = '',
  conditionForConditionalComponent,
  conditionalComponent,
  conditionalComponentId,
  disabled,
  resize,
  dropzoneText,
  fileType,
  formFieldClassName = '',
  description,
  helpText,
  id,
  inputAdornment,
  label,
  labelClassName = '',
  max,
  maxCharacters,
  maxFiles,
  min,
  nullOption,
  onBlur,
  onChange,
  options,
  placeholder,
  regionId,
  renderDropzone,
  renderFilesList,
  renderFilesAsList,
  replaceFiles,
  shouldParseInt = true,
  step,
  type = FormFieldTypes.text,
  uploadButtonText,
  uploadButtonColor,
  uploadButton,
  required = false,
  regionsOnly = false,
  rows = 2,
  switchOptions,
  autoResize = false,
  previewElement
}: FormFieldProps) => {
  const formikContext: FormikContextType<any> = useFormikContext()
  const {
    handleChange: handleFormikChange,
    setFieldValue,
    setFieldTouched,
    values
  } = formikContext

  useEffect(() => {
    if (!conditionForConditionalComponent && conditionalComponentId) {
      setFieldValue(conditionalComponentId, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionForConditionalComponent, conditionalComponentId])

  const handleBlur = (e: React.FocusEvent<any>) => {
    if (onBlur) onBlur(e)
    setFieldTouched(id, true)
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    if (onChange) onChange(e)
    handleFormikChange(e)
  }

  // TODO: refactor this component
  // step 1: make renderField it's own component
  // step 2: each field type should get its own component and prop interface - reducing the exposed props
  //         for each type to only the ones required
  // step 3: reverse the relationship - instead of FormField containing all field types, each field
  //         type should contain a FormField. Shared behaviour could be moved into a hook.
  //
  // The goal of this refactor is to have many simple components instead of one very complex one.
  // While this might not be quite as DRY it will be much more manageable. Most of the duplicated code
  // will be either bolierplate or can live in hooks and shared components which will help to keep it somewhat DRY.
  const renderField = () => {
    switch (type) {
      case FormFieldTypes.cardSwitch:
        return (
          <CardSwitch
            id={id}
            onChange={handleChange}
            disabled={disabled}
            value={getIn(values, id)}
            options={switchOptions as any}
          />
        )
      case FormFieldTypes.tabSwitch:
        return (
          <TabSwitch
            id={id}
            className={className}
            onChange={handleChange}
            disabled={disabled}
            value={getIn(values, id)}
            options={switchOptions as any}
          />
        )
      case FormFieldTypes.checkbox:
        return (
          <Field
            component={LabelledCheckbox}
            checked={getIn(values, id) === true}
            id={id}
            label={checkboxLabel}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            className={formFieldClassName}
          />
        )
      case FormFieldTypes.checkboxGroup:
        return (
          <FieldArray
            name={id}
            render={({ push, remove }: ArrayHelpers) => {
              return options?.map(opt => (
                <LabelledCheckbox
                  key={opt.value}
                  disabled={opt.disabled}
                  checked={getIn(values, id)?.includes(opt.value)}
                  id={id}
                  label={opt.label}
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // If custom change handler passed in, use that, else use FieldArray helpers
                    if (onChange) {
                      onChange(e)
                    } else if (e.target.checked) {
                      push(opt.value)
                    } else {
                      const optIndex = getIn(values, id).indexOf(opt.value)
                      remove(optIndex)
                    }
                  }}
                  value={opt.value}
                />
              ))
            }}
          />
        )
      case FormFieldTypes.datetime:
        return (
          <Field
            component={DateTimePicker}
            disabled={disabled}
            id={id}
            inputAdornment={inputAdornment}
            name={id}
            onBlur={handleBlur}
            onChange={async (value: Date) => {
              await setFieldValue(id, value)
              if (onChange) onChange(value)
            }}
            value={getIn(values, id)}
            className={formFieldClassName}
          />
        )
      case FormFieldTypes.file:
        return (
          <Field
            component={FileUpload}
            disabled={disabled}
            dropzoneText={dropzoneText}
            fileType={fileType}
            id={id}
            maxFiles={maxFiles}
            name={id}
            // Keep Formik values up-to-date with file state in the FileUpload component
            onFileChange={async (files: S3Asset[]) => {
              await setFieldValue(id, files)
              if (onChange) onChange(files)
            }}
            renderDropzone={renderDropzone}
            renderFilesAsList={renderFilesAsList}
            renderFilesList={renderFilesList}
            replaceFiles={replaceFiles}
            uploadButtonText={uploadButtonText}
            uploadButtonColor={uploadButtonColor}
            uploadButton={uploadButton}
            value={getIn(values, id)}
            previewElement={previewElement}
          />
        )
      case FormFieldTypes.radioGroup: {
        return (
          <RadioGroup id={id}>
            {options?.map(opt => {
              const valForId = getIn(values, id)
              const checked = valForId
                ? valForId.toString() === opt.value
                : false

              return (
                <Field
                  key={opt.value}
                  checked={checked}
                  component={Radio}
                  id={id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  {...opt}
                />
              )
            })}
          </RadioGroup>
        )
      }
      case FormFieldTypes.treeQuantityRadioGroup: {
        return (
          <TreeQuantityRadioGroup id={id}>
            {options?.map(opt => {
              const v = String(getIn(values, id))
              const checked = v === String(opt.value)

              return (
                <Field
                  key={opt.value}
                  checked={checked}
                  component={TreeQuantityRadio}
                  id={id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  {...opt}
                />
              )
            })}
          </TreeQuantityRadioGroup>
        )
      }
      case FormFieldTypes.select: {
        return (
          <Field
            component={Select}
            disabled={disabled}
            id={id}
            options={options}
            onBlur={handleBlur}
            // formik can't detect if a string is a number out of the box so requires some basic casting
            onChange={async (e: React.ChangeEvent<HTMLSelectElement>) => {
              if (onChange) onChange(e)

              await setFieldValue(
                id,
                // eslint-disable-next-line no-restricted-globals
                isNaN(e.target.value as any) || !shouldParseInt // Figure out why we're parsing integers here - probably should do this explicitly?
                  ? e.target.value
                  : parseInt(e.target.value, 10)
              )
            }}
            placeholder={placeholder}
            value={getIn(values, id)}
            className={formFieldClassName}
          />
        )
      }
      case FormFieldTypes.textarea: {
        return (
          <Field
            component={TextArea}
            disabled={disabled}
            id={id}
            maxCharacters={maxCharacters}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            value={getIn(values, id)}
            className={formFieldClassName}
            rows={rows}
            resize={resize}
            autoResize={autoResize}
          />
        )
      }
      case FormFieldTypes.regionSelect:
        return (
          <Field
            component={RegionSelect}
            disabled={disabled}
            id={id}
            nullOption={nullOption}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            value={getIn(values, id)}
            regionsOnly={regionsOnly}
          />
        )
      case FormFieldTypes.districtSelect:
        return (
          <Field
            component={DistrictSelect}
            disabled={disabled}
            id={id}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            regionId={regionId}
            value={getIn(values, id)}
          />
        )
      case FormFieldTypes.accountSelect:
        return (
          <Field
            component={AccountSelect}
            disabled={disabled}
            id={id}
            onBlur={handleBlur}
            onChange={onChange}
            placeholder={placeholder}
            value={getIn(values, id)}
          />
        )
      case FormFieldTypes.registrySelect:
        return (
          <Field
            component={RegistrySelect}
            disabled={disabled}
            id={id}
            onBlur={handleBlur}
            onChange={onChange}
            placeholder={placeholder}
            value={getIn(values, id)}
          />
        )
      case FormFieldTypes.textArray:
        return (
          <Field
            component={TextInputArray}
            disabled={disabled}
            id={id}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            value={getIn(values, id)}
          />
        )

      default: {
        return (
          <Field
            component={DefaultInput}
            className={formFieldClassName}
            disabled={disabled}
            id={id}
            inputAdornment={inputAdornment}
            max={max}
            min={min}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
            step={step}
            type={type}
            value={getIn(values, id)}
          />
        )
      }
    }
  }

  const isRadioOrCheckboxGroup =
    type === FormFieldTypes.radioGroup ||
    type === FormFieldTypes.checkboxGroup ||
    type === FormFieldTypes.tabSwitch ||
    type === FormFieldTypes.cardSwitch

  const descriptionComponent = useMemo(() => {
    if (!description) return null

    if (typeof description === 'string') {
      return (
        <ParagraphSmaller className='form-description-text whitespace-pre-line !m-0'>
          {description}
        </ParagraphSmaller>
      )
    }
    return description
  }, [description])

  const helpTextComponent = useMemo(() => {
    if (!helpText) return null

    if (typeof helpText === 'string') {
      return (
        <ParagraphSmaller className='form-help-text whitespace-pre-line !m-0'>
          {helpText}
        </ParagraphSmaller>
      )
    }
    return helpText
  }, [helpText])

  return (
    <>
      <FormControlWrapper
        className={className}
        isRadioOrCheckboxGroup={isRadioOrCheckboxGroup}
      >
        <>
          {isRadioOrCheckboxGroup ? (
            <legend className={`form-legend ${labelClassName}`} id={id}>
              {label}
              {required ? <RequiredIndicator /> : null}
            </legend>
          ) : (
            <label className={`form-label ${labelClassName}`} htmlFor={id}>
              {label}
              {required ? <RequiredIndicator /> : null}
            </label>
          )}
          <div className='flex flex-col gap-[10px]'>
            {descriptionComponent}
            <div className='inline-flex flex-col'>{renderField()}</div>
            {helpTextComponent}
            <ErrorMessage name={id}>
              {(msg: string) => (
                <div className='form-error-message' role='alert'>
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>
        </>
      </FormControlWrapper>
      {conditionForConditionalComponent && conditionalComponent}
    </>
  )
}
