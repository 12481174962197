/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PropsWithChildren, useCallback, useState } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import { useUser } from 'src/providers/UserProvider'

import { Image } from 'src/components/atoms/Image'
import { InternalLink } from 'src/components/atoms/Link'
import { MenuSectionTitle, Paragraph } from 'src/components/atoms/Typography'

import { viewAccountDetailPath } from 'src/config/paths'
import { env } from 'src/env/client.mjs'
import colors from 'src/styles/theme/colors'
import { camelToFlat } from 'src/utils/common'

import { AccountSwitcher } from '../AccountSwitcher'

const DEFAULT_PROFILE = 'assets/img/default-profile.png'

export const AccountInfo = () => {
  const { user, memberships } = useUser()

  const [accountsVisible, setAccountsVisible] = useState(false)

  const AccountInfoWrapper = useCallback(
    ({ children }: PropsWithChildren) => {
      if (memberships?.length === 1) {
        return (
          <InternalLink className='block' href={viewAccountDetailPath}>
            {children}
          </InternalLink>
        )
      }
      return <div className='w-full'>{children}</div>
    },
    [memberships]
  )

  return (
    <div className='flex flex-col items-start'>
      <MenuSectionTitle className='text-anotherGrey2'>Account</MenuSectionTitle>
      <AccountInfoWrapper>
        <div
          className='w-full max-w-full py-[10px] flex flex-row gap-[10px] items-center group'
          tabIndex={0}
          role='button'
          onKeyDown={() => {}}
          onClick={() => setAccountsVisible(!accountsVisible)}
        >
          <div className='flex-shrink-0 h-[54px] w-[54px] relative overflow-hidden rounded-full border border-anotherGrey2'>
            {user?.Account?.Avatar &&
            user?.Account?.Avatar !== DEFAULT_PROFILE ? (
              <Image
                className='object-cover'
                src={`${env.NEXT_PUBLIC_ASSETS_DOMAIN}/${user?.Account?.Avatar}`}
                alt={`Avatar for ${user?.Account?.Name}`}
                fill
              />
            ) : (
              <FaUserAlt
                className='w-full h-full p-3'
                color={colors.anotherGrey2}
              />
            )}
          </div>
          <div className='flex-1 flex flex-col gap-[5px] items-start justify-center overflow-hidden'>
            <Paragraph className='text-left w-full !m-0 text-charcoal group-hover:!text-primary font-medium !text-[16px] leading-[20px]'>
              {user?.Account?.Name}
            </Paragraph>
            <Paragraph className='text-left w-full !m-0 text-anotherGrey2 group-hover:!text-primary font-medium !text-sm truncate'>
              {camelToFlat(user?.Account?.Type || '')}
            </Paragraph>
          </div>
          {(memberships?.length || 0) > 1 ? (
            <div className='flex-shrink-0 group-hover:!text-primary'>
              {accountsVisible ? (
                <MdKeyboardArrowUp size='20px' />
              ) : (
                <MdKeyboardArrowDown size='20px' />
              )}
            </div>
          ) : null}
        </div>
      </AccountInfoWrapper>
      <div
        className={`w-full flex flex-col transition-[max-height] duration-200 overflow-hidden ${
          accountsVisible ? 'max-h-96' : 'max-h-0'
        }`}
      >
        <AccountSwitcher
          onAccountClick={() => {
            setAccountsVisible(false)
          }}
        />
      </div>
    </div>
  )
}
