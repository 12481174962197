import { useRouter } from 'next/router'

import { useGetAdminPurchaseQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const AdminViewFundingBreadcrumb = () => {
  const router = useRouter()

  const { purchaseId } = router.query as { purchaseId: string }

  const { data: purchaseData } = useGetAdminPurchaseQuery({
    variables: { purchaseId }
  })

  const ref = purchaseData?.AdminPurchaseView[0]?.Reference
  const breadcrumbText = truncateBreadcrumbText(
    `Funding${ref ? ` #${ref}` : ''}`
  )

  return <span>{breadcrumbText}</span>
}
