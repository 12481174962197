import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'
import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { Heading5 } from 'src/components/atoms/Typography/Typography'
import { HStack } from 'src/components/templates/HStack'

import {
  useDeleteDraftApplicationMutation,
  useGetAdminApplicationLazyQuery
} from 'src/config/generated/graphql'
import { dashboardPath } from 'src/config/paths'

export interface DeleteApplicationModalProps {
  applicationId?: string
  closeModal: () => void
}

export const DeleteApplicationModal = ({
  applicationId,
  closeModal
}: DeleteApplicationModalProps) => {
  const { user } = useUser()
  const router = useRouter()
  const planterId = user?.Account?.PlanterId

  const [getApplication, { data: applicationData }] =
    useGetAdminApplicationLazyQuery()

  const [deleteDraftApplication] = useDeleteDraftApplicationMutation()

  useEffect(() => {
    if (applicationId) {
      getApplication({ variables: { id: applicationId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const handleDeleteApplication = () => {
    deleteDraftApplication({
      variables: { id: applicationId, planterId },
      onCompleted: async response => {
        if (response.delete_Application?.affected_rows === 1) {
          notify({
            message: 'Successfully deleted application',
            type: NotificationType.success
          })
          closeModal()
          await router.replace(dashboardPath())
        } else {
          notify({
            message: 'We could not delete your application',
            type: NotificationType.error
          })
          closeModal()
        }
      }
    })
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3 className='mb-5'>
        Delete application #{applicationData?.Application?.Reference}
      </Heading3>
      <Heading5 className='mb-5'>{applicationData?.Application?.Name}</Heading5>
      <Paragraph>
        You are about to delete an application. This will remove all the
        information you&apos;ve already provided.
      </Paragraph>
      <Paragraph>Are you sure you want to delete this application?</Paragraph>
      <HStack className='mt-10 flex justify-between'>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Cancel
        </Button>
        <Button
          className='mr-2'
          onClick={handleDeleteApplication}
          type='submit'
        >
          Yes, delete application
        </Button>
      </HStack>
    </div>
  )
}
