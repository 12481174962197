import { useRouter } from 'next/router'

import { useUser } from 'src/providers/UserProvider'

import { useGetProjectQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const ViewProjectDetailBreadcrumb = () => {
  const { user } = useUser()
  const { projectId } = useRouter().query as { projectId: string }

  const { data: projectData } = useGetProjectQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: projectId, planterId: user?.Account?.PlanterId },
    skip: !user?.Account?.PlanterId
  })
  const name = projectData?.Project[0]?.Name || ''

  const breadcrumbText = truncateBreadcrumbText(name || 'Project')

  return <span>{breadcrumbText}</span>
}
