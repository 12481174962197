import { FaCog, FaSignOutAlt } from 'react-icons/fa'

import { useAuth } from 'src/providers/AuthProvider'
import { useUser } from 'src/providers/UserProvider'

import { Divider } from 'src/components/atoms/Divider'
import { InternalLink, OnClickLink } from 'src/components/atoms/Link'
import { Switch } from 'src/components/atoms/Switch'
import { AccountInfo } from 'src/components/molecules/AccountInfo'
import { UserInfo } from 'src/components/molecules/UserInfo'

import { viewAccountDetailPath } from 'src/config/paths'

export const AccountMenu = () => {
  const { isStaff, isAdvisor, logout, login, signup } = useAuth()
  const { isAdminMode, setAdminMode, user } = useUser()

  const handleChangeAdminToggle = () => {
    setAdminMode(!isAdminMode)
  }

  return (
    <div className='min-w-[340px] max-w-[340px] z-0 rounded-none bg-white shadow-[0px_4px_8px_2px_rgba(51,51,51,0.15)]'>
      {/* admin mode switch */}
      <div className='bg-mostlyGreen'>
        {isStaff || isAdvisor ? (
          <>
            <div className='space-x-4 p-5 text-white'>
              <span>{isAdminMode ? 'Admin' : 'App'}</span>
              <Switch
                title='Admin'
                checked={isAdminMode}
                onChange={handleChangeAdminToggle}
              />
            </div>
            <Divider className='!m-0' />
          </>
        ) : null}
        <div className='bg-mostlyGreen p-5'>
          <UserInfo />
        </div>
      </div>
      {user?.Account ? (
        <div className='p-5'>
          <AccountInfo />
        </div>
      ) : null}
      <Divider className='!m-0' />
      {user ? (
        <>
          <InternalLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            highlightActive
            href={viewAccountDetailPath}
          >
            Account settings <FaCog />
          </InternalLink>
          <Divider className='!m-0' />
          <OnClickLink
            color='black'
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            onClick={logout}
          >
            Log out <FaSignOutAlt />
          </OnClickLink>
        </>
      ) : (
        <>
          <OnClickLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            onClick={signup}
          >
            Sign up
          </OnClickLink>
          <Divider className='!m-0' />
          <OnClickLink
            color='black'
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            onClick={login}
          >
            Log in
          </OnClickLink>
        </>
      )}
    </div>
  )
}
