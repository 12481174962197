import { useEffect } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useGetAdminApplicationLazyQuery,
  useUpdateApplicationMutation
} from 'src/config/generated/graphql'
import { ApplicationWorkflowState } from 'src/types/application'

export interface DeclineApplicationModalProps {
  applicationId?: string
  closeModal: () => void
}

export const DeclineApplicationModal = ({
  applicationId,
  closeModal
}: DeclineApplicationModalProps) => {
  const [getApplication, { data: applicationData }] =
    useGetAdminApplicationLazyQuery()

  const [updateApplicationState] = useUpdateApplicationMutation({
    onCompleted: () =>
      notify({
        message: 'Successfully declined application',
        type: NotificationType.success
      }),
    refetchQueries: [
      namedOperations.Query.getAdminApplication,
      namedOperations.Query.listAdminApplicationsView
    ]
  })

  useEffect(() => {
    if (applicationId) {
      getApplication({ variables: { id: applicationId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const handleDeclineApplication = () => {
    if (applicationId) {
      updateApplicationState({
        variables: {
          id: applicationId,
          set: {
            WorkflowState: ApplicationWorkflowState.Declined
          }
        },
        onCompleted: closeModal
      })
    }
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>
        Decline application #{applicationData?.Application?.Reference}
      </Heading3>

      <Paragraph>Are you sure you wish to decline this application?</Paragraph>
      <Paragraph>
        The application will also be closed and you will not be able to match
        transactions.
      </Paragraph>
      <HStack>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Cancel
        </Button>
        <Button
          className='mr-2'
          onClick={handleDeclineApplication}
          type='submit'
        >
          Confirm
        </Button>
      </HStack>
    </div>
  )
}
