import { PropsWithChildren, createContext, useContext, useMemo } from 'react'

export type FormLayoutContextProps = {
  margin?: 'base' | 'small'
}

export const FormLayoutContext = createContext<FormLayoutContextProps>({
  margin: 'base'
})

export const FormLayoutProvider = ({
  children,
  margin
}: PropsWithChildren<FormLayoutContextProps>) => {
  const value = useMemo<FormLayoutContextProps>(
    () => ({
      margin
    }),
    [margin]
  )

  return (
    <FormLayoutContext.Provider value={value}>
      {children}
    </FormLayoutContext.Provider>
  )
}

export const useFormLayout = () => useContext(FormLayoutContext)
