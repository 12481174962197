import { useUser } from 'src/providers/UserProvider'

export const UserInfo = () => {
  const { user } = useUser()

  return (
    <div className='flex flex-col items-start gap-[5px]'>
      <span className='text-[16px] font-bold text-white'>
        Kia ora {user?.FirstName || ''}
      </span>
      {user ? (
        <span className='text-[14px] text-white font-medium'>
          {user?.Email}
        </span>
      ) : null}
    </div>
  )
}
