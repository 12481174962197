import { IconProps } from './types'

export const Spade = ({ className }: IconProps) => {
  return (
    <svg
      width='100%'
      height='100%'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon/spade'>
        <path
          id='Vector'
          d='M13.7262 2.2759C13.9029 2.09924 14.1425 2 14.3923 2C14.6422 2 14.8818 2.09924 15.0585 2.2759L16.3871 3.60456L16.3909 3.60833L16.3956 3.6121L17.7242 4.94077C17.9009 5.11759 18.0001 5.35735 18 5.60732C17.9999 5.85729 17.9005 6.09699 17.7237 6.27368C17.5469 6.45037 17.3072 6.54958 17.0572 6.5495C16.8073 6.54941 16.5676 6.45002 16.3909 6.27321L15.7247 5.60699L11.0605 10.2715L12.3929 11.6039C12.5679 11.7789 12.7068 11.9867 12.8016 12.2154C12.8963 12.4441 12.9451 12.6892 12.9451 12.9368C12.9451 13.1844 12.8963 13.4295 12.8016 13.6582C12.7068 13.8869 12.5679 14.0947 12.3929 14.2697L10.0428 16.6198C9.42414 17.2386 8.64535 17.6729 7.79374 17.874C6.94214 18.075 6.05137 18.0349 5.22127 17.7582L4.32705 17.4604C3.91073 17.3215 3.53246 17.0876 3.2222 16.7771C2.91195 16.4667 2.67824 16.0883 2.53958 15.6719L2.24182 14.7786C1.96509 13.9484 1.925 13.0576 2.12604 12.2059C2.32709 11.3543 2.76132 10.5755 3.38008 9.95672L5.73009 7.60564C5.90509 7.43056 6.11288 7.29167 6.34157 7.19691C6.57026 7.10215 6.81538 7.05338 7.06292 7.05338C7.31047 7.05338 7.55559 7.10215 7.78428 7.19691C8.01297 7.29167 8.22076 7.43056 8.39576 7.60564L9.72813 8.93902L14.3923 4.27455L13.7262 3.60833C13.5495 3.43162 13.4503 3.19198 13.4503 2.94212C13.4503 2.69225 13.5495 2.45261 13.7262 2.2759Z'
          fill='currentColor'
        />
      </g>
    </svg>
  )
}
