import { IconProps } from './types'

export const TallArrowRight = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16.24 51.04'
      className={className}
    >
      <path d='M1 51a.9.9 0 0 1-.5-.1 1.11 1.11 0 0 1-.4-1.4l13.7-23.6L.14 1.54a1 1 0 0 1 .4-1.4 1 1 0 0 1 1.4.4l14.3 25.4-14.3 24.6A1.19 1.19 0 0 1 1 51z' />
    </svg>
  )
}
