import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { useEffect } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useGetAdminApplicationLazyQuery,
  useUpdateApplicationMutation
} from 'src/config/generated/graphql'
import { ApplicationWorkflowState } from 'src/types/application'
import { FormFieldTypes } from 'src/types/form'

import { ApproveApplicationFormValues } from './types'
import validationSchema from './validationSchema'

export interface ApproveApplicationModalProps {
  applicationId?: string
  closeModal: () => void
}

export const ApproveApplicationModal = ({
  applicationId,
  closeModal
}: ApproveApplicationModalProps) => {
  const [getApplication, { data: applicationData }] =
    useGetAdminApplicationLazyQuery()

  const [updateApplicationState] = useUpdateApplicationMutation({
    onCompleted: () =>
      notify({
        message: 'Successfully approved application',
        type: NotificationType.success
      }),
    refetchQueries: [
      namedOperations.Query.getAdminApplication,
      namedOperations.Query.listAdminApplicationsView
    ]
  })

  useEffect(() => {
    if (applicationId) {
      getApplication({ variables: { id: applicationId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const handleApproveApplication = (values: ApproveApplicationFormValues) => {
    if (applicationId) {
      updateApplicationState({
        variables: {
          id: applicationId,
          set: {
            Approved: values.Approved,
            ApprovedAt: dayjs().toISOString(),
            WorkflowState: ApplicationWorkflowState.Approved
          }
        },
        onCompleted: closeModal
      })
    }
  }

  const handleClickCancel = (resetForm: () => void) => {
    resetForm()
    closeModal()
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Approve this application</Heading3>
      <Paragraph>
        Enter the number of trees approved for this application:
      </Paragraph>
      <Formik
        initialValues={{
          Planned: applicationData?.Application?.Planned || '0',
          Requested: applicationData?.Application?.Required || '0',
          Approved: 0
        }}
        enableReinitialize
        onSubmit={handleApproveApplication}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm }) => {
          return (
            <Form>
              <FormField
                id='Planned'
                disabled
                label='Trees planned'
                type={FormFieldTypes.number}
              />
              <FormField
                id='Requested'
                disabled
                label='Trees requested'
                type={FormFieldTypes.number}
              />
              <FormField
                id='Approved'
                label='Trees approved'
                type={FormFieldTypes.number}
              />
              <HStack>
                <Button
                  className='mr-2'
                  onClick={() => handleClickCancel(resetForm)}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button disabled={isSubmitting} className='mr-2' type='submit'>
                  Approve
                </Button>
              </HStack>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
