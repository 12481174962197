import { IconProps } from './types'

export const TallArrowLeft = ({ className }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16.24 51.04'
      className={className}
    >
      <path d='M15.2 51a1.19 1.19 0 0 1-.9-.5L0 25.94 14.3.54a1 1 0 0 1 1.4-.4 1 1 0 0 1 .4 1.4L2.4 25.94l13.7 23.6a1 1 0 0 1-.4 1.4.9.9 0 0 1-.5.06z' />
    </svg>
  )
}
