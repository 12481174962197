import Decimal from 'decimal.js'

export const enumToArray = (enums: any) => {
  return Object.keys(enums).filter(key => Number.isNaN(Number(key)))
}

export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find(key => object[key] === value)
}

export function formattedPrice({
  price,
  showCents = true
}: {
  price: number | string
  showCents?: boolean
}): string {
  const parsedPrice: number =
    typeof price === 'string' ? parseFloat(price.replace(/,/g, '')) : price

  return parsedPrice
    ? new Decimal(Math.abs(parsedPrice))
        .toFixed(showCents ? 2 : 0)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : new Decimal(0).toFixed(showCents ? 2 : 0)
}

export function numberInWords(number: number) {
  const numbers = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine'
  ]
  return number < 10 ? numbers[number] : number.toString()
}

export const LOWER_CARBON_OFFSET_MULTIPLIER = 0.16530601
export const UPPER_CARBON_OFFSET_MULTIPLIER = 0.22760472

export const camelToFlat = (c: string) => {
  const parts = c.replace(/[A-Z]/g, ' $&').split(' ').filter(Boolean)
  const res = parts.map((p, i) => (i === 0 ? p : p.toLowerCase())).join(' ')
  return res
}
