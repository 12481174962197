import Image, { ImageProps } from 'next/image'

export const StyledThumbnailContainer = ({
  children
}: {
  children: React.ReactNode
}) => {
  return <div className='flex flex-col mb-2 mr-2'>{children}</div>
}

export const StyledThumbnail = ({
  children
}: {
  children: React.ReactNode
}) => {
  return <div className='inline-flex w-36 h-36'>{children}</div>
}

export const StyledThumbnailInner = ({
  children
}: {
  children: React.ReactNode
}) => {
  return (
    <div className='flex relative w-full h-full overflow-hidden'>
      {children}
    </div>
  )
}

export const StyledThumbnailImage = ({ alt, ...props }: ImageProps) => {
  return (
    <Image
      alt={alt}
      className='inline max-w-full max-h-full m-auto'
      {...props}
    />
  )
}
