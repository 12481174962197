/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react'

import { dateToInputValues, inputValuesToDate } from './utils'

export interface DateTimePickerProps {
  className?: string
  onChange: (e: Date) => void
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  value: Date | string | undefined
}

export const DateTimePicker = ({
  className = '',
  onChange,
  onBlur,
  value
}: DateTimePickerProps) => {
  const { date, time } = dateToInputValues(
    typeof value === 'string' ? new Date(value) : value
  )

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { time: fallbackTime } = dateToInputValues(new Date())
    onChange(inputValuesToDate(e.target.value, time || fallbackTime))
  }

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { date: fallbackDate } = dateToInputValues(new Date())
    onChange(inputValuesToDate(date || fallbackDate, e.target.value))
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e)
  }

  return (
    <div className={`flex flex-col md:flex-row w-full gap-4 pt-4 ${className}`}>
      <input
        type='date'
        aria-label='date'
        role='textbox'
        className='form-input-wrapper bg-white'
        onChange={handleDateChange}
        onBlur={handleBlur}
        value={date}
      />
      <input
        type='time'
        aria-label='time'
        role='textbox'
        className='form-input-wrapper bg-white'
        onChange={handleTimeChange}
        onBlur={handleBlur}
        value={time}
      />
    </div>
  )
}
