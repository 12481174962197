export const DEFAULT_SELECT_VALUE = ''
export const DEFAULT_SELECT_OPTION = {
  label: 'Please select...',
  value: DEFAULT_SELECT_VALUE,
  disabled: true
}

export const ALL_SELECT_OPTION = {
  label: 'All',
  value: 'all'
}

export const booleanToYesOrNo = (value?: boolean | null) => {
  if (value === undefined || value === null) return null

  return value ? 'Yes' : 'No'
}
export const getStandaloneCheckboxOption = (boolValue?: boolean) => [
  booleanToYesOrNo(boolValue)
]

export const yesOrNoStringToBoolean = (value: string) => {
  switch (value) {
    case 'Yes':
      return true
    case 'No':
      return false
    default:
      return undefined
  }
}
