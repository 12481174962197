import { useRouter } from 'next/router'

import { useGetAdminApplicationQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const AdminViewApplicationBreadcrumb = () => {
  const { applicationId } = useRouter().query as { applicationId: string }

  const { data: applicationData } = useGetAdminApplicationQuery({
    variables: { id: applicationId }
  })

  const name = `${applicationData?.Application?.Reference}`
  const breadcrumbText = truncateBreadcrumbText(name)

  return <span>{breadcrumbText}</span>
}
