import React, { PropsWithChildren } from 'react'

export const StyledDropzone = React.memo(
  React.forwardRef<HTMLDivElement, PropsWithChildren>(
    ({ children, ...props }, ref) => (
      <div
        ref={ref}
        {...props}
        className='flex flex-1 flex-col items-center px-20 py-10 border-2 rounded-sm border-slate-200 border-dashed bg-white text-slate-400 outline-none'
      >
        {children}
      </div>
    )
  )
)
