import { IconProps } from './types'

export const Seedling = ({ className }: IconProps) => {
  return (
    <svg
      width='100%'
      height='100%'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='seedling'
        d='M16 1C16 4.55 13.3562 7.48438 9.93125 7.9375C9.70937 6.26875 8.975 4.7625 7.89062 3.58437C9.0875 1.44687 11.375 0 14 0H15C15.5531 0 16 0.446875 16 1ZM0 3C0 2.44688 0.446875 2 1 2H2C5.86562 2 9 5.13438 9 9V10V15C9 15.5531 8.55313 16 8 16C7.44688 16 7 15.5531 7 15V10C3.13438 10 0 6.86562 0 3Z'
        fill='currentColor'
      />
    </svg>
  )
}
