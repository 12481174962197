import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'
import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Heading5, Paragraph } from 'src/components/atoms/Typography'
import { HStack } from 'src/components/templates/HStack'

import {
  useDeletePlantingMutation,
  useGetPlantingLazyQuery
} from 'src/config/generated/graphql'
import { viewApplicationPath } from 'src/config/paths'

export interface DeletePlantingModalProps {
  plantingId?: string
  closeModal: () => void
}

export const DeletePlantingModal = ({
  plantingId,
  closeModal
}: DeletePlantingModalProps) => {
  const { user } = useUser()
  const router = useRouter()
  const [deletePlanting] = useDeletePlantingMutation()
  const [getPlanting, { data, loading }] = useGetPlantingLazyQuery()

  useEffect(() => {
    if (user?.Account?.PlanterId && plantingId) {
      getPlanting({
        variables: {
          id: plantingId,
          planterId: user.Account.PlanterId
        }
      })
    }
  }, [getPlanting, plantingId, user])

  const handleDeletePlanting = () => {
    deletePlanting({
      variables: {
        id: plantingId
      },
      onCompleted: response => {
        if (response.delete_Planting_by_pk?.Id) {
          notify({
            message: 'Successfully deleted planting report',
            type: NotificationType.success
          })
          router.replace(
            viewApplicationPath({
              routeParams: {
                applicationId: data?.Planting[0]?.ApplicationId
              }
            })
          )
        } else {
          notify({
            message: 'We could not delete your planting report',
            type: NotificationType.error
          })
        }
        closeModal()
      }
    })
  }

  if (loading || !data) return null

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Delete report</Heading3>
      <Heading5>{data?.Planting[0]?.Name}</Heading5>
      <Paragraph>
        You are about to delete a planting report. This will remove all the
        information you&apos;ve already provided.
      </Paragraph>
      <Paragraph>Are you sure you want to delete this application?</Paragraph>
      <HStack className='mt-10 flex justify-between'>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Cancel
        </Button>
        <Button className='mr-2' onClick={handleDeletePlanting} type='submit'>
          Yes, delete report
        </Button>
      </HStack>
    </div>
  )
}
