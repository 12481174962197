import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

import { Image } from 'src/components/atoms/Image'
import { InternalLink } from 'src/components/atoms/Link'
import { Heading1, Paragraph } from 'src/components/atoms/Typography'
import {
  FullWidthContainer,
  FullWidthContainerBackgroundColor
} from 'src/components/layouts/Container'
import { Page, PageBackgroundColor } from 'src/components/layouts/Page'

import { homePath } from 'src/config/paths'

export const NotFound = () => {
  const router = useRouter()

  useEffect(() => {
    router.replace('/404', router.asPath)
  }, [router])

  return <ClipLoader cssOverride={{ display: 'block' }} className='m-auto' />
}

const FourOhFourPage: NextPage = () => {
  return (
    <Page isFullWidth backgroundColor={PageBackgroundColor.white}>
      <FullWidthContainer
        width='base'
        backgroundColor={FullWidthContainerBackgroundColor.white}
      >
        <div className='flex flex-col-reverse md:flex-row md:gap-8 md:items-center'>
          <div className='flex flex-1 flex-col'>
            <Image
              alt='Trees That Count logo'
              src='/img/brand-name.png'
              height={1063}
              width={827}
              layout='responsive'
            />
          </div>
          <div className='flex-1'>
            <Heading1 id='pageDontExistTitle' className='text-center pb-10'>
              Sorry, that page doesn&apos;t exist!
            </Heading1>
            <Paragraph>
              It looks like the page you&apos;re trying to go to does not exist,
              or is no longer available.
            </Paragraph>
            <Paragraph>
              Return to the{' '}
              <InternalLink href={homePath}>homepage</InternalLink>
            </Paragraph>
          </div>
        </div>
      </FullWidthContainer>
    </Page>
  )
}

export default FourOhFourPage
