export interface FormValues {
  [key: string]: any
}

export enum FormFieldTypes {
  address = 'address',
  checkbox = 'checkbox',
  checkboxGroup = 'checkboxGroup',
  date = 'date',
  datetime = 'datetime',
  districtSelect = 'districtSelect',
  email = 'email',
  file = 'file',
  number = 'number',
  password = 'password',
  accountSelect = 'accountSelect',
  radioGroup = 'radioGroup',
  regionSelect = 'regionSelect',
  registrySelect = 'registrySelect',
  search = 'search',
  sort = 'sort',
  select = 'select',
  text = 'text',
  tel = 'tel',
  textarea = 'textarea',
  textArray = 'textArray',
  treeQuantityRadioGroup = 'treeQuantityRadioGroup',
  tabSwitch = 'tabSwitch',
  cardSwitch = 'cardSwitch'
}

export enum YesNoRadioOptionSequence {
  YesFirst,
  NoFirst
}

export interface Option {
  label: string
  selectedContent?: JSX.Element | string
  value?: string
  disabled?: boolean
  selectedContentAwlaysVisible?: boolean
}

export enum InputAdornmentType {
  start = 'start',
  end = 'end'
}

export interface InputAdornment {
  content: React.ReactNode
  title: string
  type: InputAdornmentType
}

export interface AssetRef {
  Uri: string
  Id?: string
  FileName?: string | null
}

export type LocalAsset = AssetRef & File

export type S3Asset = AssetRef &
  File & {
    Id: string
  }
