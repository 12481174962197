import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { useEffect, useMemo } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useGetAdminApplicationLazyQuery,
  useListSeasonsQuery,
  useUpdateApplicationSeasonMutation
} from 'src/config/generated/graphql'
import { FormFieldTypes } from 'src/types/form'

import { UpdateApplicationSeasonFormValues } from './types'
import validationSchema from './validationSchema'

export interface UpdateApplicationSeasonModalProps {
  applicationId?: string
  closeModal: () => void
}

export const UpdateApplicationSeasonModal = ({
  applicationId,
  closeModal
}: UpdateApplicationSeasonModalProps) => {
  const [getApplication, { data: applicationData }] =
    useGetAdminApplicationLazyQuery()
  const { data: seasons } = useListSeasonsQuery()

  const seasonsOptions = useMemo(() => {
    return seasons?.Season.map(season => ({
      value: season.Id,
      label: season.Name
    }))
  }, [seasons?.Season])

  const [updateApplicationSeason] = useUpdateApplicationSeasonMutation({
    onCompleted: () => {
      notify({
        message: 'Successfully updated application',
        type: NotificationType.success
      })
      closeModal()
    },
    onError: response => {
      notify({ message: response.message, type: NotificationType.error })
    },
    refetchQueries: [
      namedOperations.Query.getAdminApplication,
      namedOperations.Query.listAdminApplicationsView
    ]
  })

  useEffect(() => {
    if (applicationId) {
      getApplication({ variables: { id: applicationId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const handleClickCancel = (resetForm: () => void) => {
    resetForm()
    closeModal()
  }

  const handleUpdateApplicationSeason = async (
    values: UpdateApplicationSeasonFormValues
  ) => {
    await updateApplicationSeason({
      variables: {
        input: {
          ApplicationId: applicationId,
          Season: values.Season
        }
      }
    })
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Update application season</Heading3>
      <Paragraph>Enter the new season for this application:</Paragraph>
      <Formik
        initialValues={{
          Season:
            applicationData?.Application?.Season?.Id ||
            dayjs().year().toString()
        }}
        enableReinitialize
        onSubmit={handleUpdateApplicationSeason}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm }) => {
          return (
            <Form>
              <div className='mb-3'>
                <dl>
                  <dt className='inline mr-4 font-bold'>Current season</dt>
                  <dd className='inline'>
                    {applicationData?.Application?.Season?.Name}
                  </dd>
                </dl>
              </div>
              <div className='flex space-x-2'>
                <FormField
                  id='Season'
                  label='New season'
                  type={FormFieldTypes.select}
                  options={seasonsOptions}
                />
              </div>
              <HStack>
                <Button
                  className='mr-2'
                  onClick={() => handleClickCancel(resetForm)}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button disabled={isSubmitting} className='mr-2' type='submit'>
                  Update
                </Button>
              </HStack>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
