import { useRouter } from 'next/router'

import { useUser } from 'src/providers/UserProvider'

import { useGetPlantingQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const ViewPlantingBreadcrumb = () => {
  const { plantingId } = useRouter().query as { plantingId: string }
  const { user } = useUser()

  const { data: plantingData } = useGetPlantingQuery({
    variables: { id: plantingId, planterId: user?.Account?.PlanterId }
  })

  const name = plantingData?.Planting?.[0]?.Name
  const breadcrumbText = truncateBreadcrumbText(name || 'Planting')

  return <span>{breadcrumbText}</span>
}
