import dayjs, { OpUnitType, QUnitType } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(advancedFormat)

export const formatDate = ({
  date,
  format,
  isUtc
}: {
  date: string
  format: string
  isUtc?: boolean
}) => {
  if (!date) return null

  // NOTE: ISO 8601 dates stored in/created by Hasura are in UTC, however
  // they are not formatted correctly with a trailing "Z", which means we need
  // to be explicit about where a date we want to format is in UTC or local
  return isUtc
    ? dayjs.utc(date).local().format(format)
    : dayjs(date).format(format)
}

export const difference = ({
  date1,
  date2 = dayjs().toISOString(),
  isUtc,
  unit = 'day'
}: {
  date1: string
  date2?: string
  isUtc?: boolean
  unit?: QUnitType | OpUnitType
}) => {
  return isUtc
    ? dayjs.utc(date1).diff(date2, unit)
    : dayjs(date1).diff(dayjs(date2), unit)
}

export const determineFinancialYear = (date: string) => {
  // Takes a iso8601 timestamp and returns the financial year it belongs to
  // If the month is after April, then it belongs to the current year's financial year.
  // If the month is before April, then it belongs to the previous year's financial year.
  const dateObj = dayjs.utc(date)
  const year = dateObj.year()
  const month = dateObj.month()

  if (month >= 3 && month <= 11) return year + 1
  return year
}
