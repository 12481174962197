import { UseQueryResult } from 'react-query'

import { LoadingSpinner } from 'src/components/atoms/LoadingSpinner'

import { NotFound } from 'src/pages/404/404'
import { PageQueryResult } from 'src/server/router/sanity/groq/post/types'
import { getSanityImageUrl } from 'src/utils/sanity'
import { trpc } from 'src/utils/trpc'

import { Page, PageProps } from '.'

export type SanityPageComponent = (
  props: UseQueryResult<PageQueryResult>
) => JSX.Element | null | (JSX.Element | string | null)[]

interface SanityPageProps extends Omit<PageProps, 'children'> {
  slug: string
  children: SanityPageComponent
}

export const SanityPage = ({
  slug,
  children,
  ...pageProps
}: SanityPageProps) => {
  const response: UseQueryResult<PageQueryResult> = trpc.useQuery([
    'sanity.getPageBySlug',
    { slug }
  ])

  const { data, isLoading } = response

  // Redirect if page not found
  if (!isLoading && !data) {
    return <NotFound />
  }

  const { hero } = data || {}

  return (
    <Page
      title={data?.metaTitle || data?.title}
      metaTitle={data?.metaTitle || data?.title}
      metaDescription={data?.metaDescription || data?.description}
      image={data?.openGraphImage && getSanityImageUrl(data.openGraphImage)}
      heroAction={hero?.buttons}
      heroImage={hero?.backgroundImage}
      heroSubtitle={hero?.tagline}
      heroTitle={hero?.heading}
      showHero={!!hero}
      {...pageProps}
    >
      <LoadingSpinner isLoading={isLoading}>
        {children(response)}
      </LoadingSpinner>
    </Page>
  )
}
