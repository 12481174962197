import createImageUrlBuilder from '@sanity/image-url'
import {
  FitMode,
  SanityAsset,
  SanityImageSource,
  SanityProjectDetails
} from '@sanity/image-url/lib/types/types'
import { ClientConfig, createClient } from 'next-sanity'

import { env } from 'src/env/client.mjs'
import { clientEnv } from 'src/env/schema.mjs'
import { Stages } from 'src/types/stages'

const sanityConfig: ClientConfig = {
  projectId: clientEnv.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: clientEnv.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2022-03-25',
  useCdn: env.NEXT_PUBLIC_APP_STAGE === Stages.PRODUCTION
}

export const sanityClient = createClient(sanityConfig)

const imageUrlBuilderConfig: SanityProjectDetails = {
  projectId: sanityConfig.projectId!,
  dataset: sanityConfig.dataset!
}

function isSanityAsset(source: any): source is SanityAsset {
  if ('asset' in source && 'extension' in source.asset) return true
  return false
}

export const getSanityImageUrl = (
  source: SanityImageSource,
  width?: number,
  height?: number,
  fitMode?: FitMode,
  forceDownload?: boolean | string
) => {
  let builder = createImageUrlBuilder(imageUrlBuilderConfig)
    .image(source)
    .fit(fitMode || 'crop')

  if (isSanityAsset(source) && source.asset.extension === 'gif') {
    // don't format gifs, we want it to animate
    // To have this work, we need to get the asset
    // with all it's attributes: `'asset':asset->`
  } else builder = builder.format('webp')

  if (width) builder = builder.width(width)
  if (height) builder = builder.height(height)
  if (isSanityAsset(source) && forceDownload)
    builder = builder.forceDownload(
      `${forceDownload}.${source.asset.extension}`
    )

  return builder.url()
}
