import { Form, Formik } from 'formik'
import { useEffect } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'
import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { Heading5 } from 'src/components/atoms/Typography/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useCreateActionNoteMutation,
  useGetAdminApplicationLazyQuery,
  useUpdateApplicationMutation
} from 'src/config/generated/graphql'
import { ActionNoteAction, ActionNoteEntity } from 'src/types/actionNotes'
import { ApplicationWorkflowState } from 'src/types/application'
import { FormFieldTypes } from 'src/types/form'

import { WithdrawApplicationFormValues } from './types'
import validationSchema from './validationSchema'

export interface WithdrawApplicationModalProps {
  applicationId?: string
  isAdmin?: boolean
  closeModal: () => void
}

export const WithdrawApplicationModal = ({
  applicationId,
  isAdmin,
  closeModal
}: WithdrawApplicationModalProps) => {
  const { user } = useUser()
  const [getApplication, { data: applicationData }] =
    useGetAdminApplicationLazyQuery()
  const [createActionNote] = useCreateActionNoteMutation()

  const [updateApplicationState] = useUpdateApplicationMutation({
    onCompleted: () =>
      notify({
        message: 'Successfully withdrawn application',
        type: NotificationType.success
      }),
    refetchQueries: [namedOperations.Query.listAdminApplicationsView]
  })

  useEffect(() => {
    if (applicationId) {
      getApplication({ variables: { id: applicationId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const handleWithdrawApplication = async ({
    Notes
  }: WithdrawApplicationFormValues) => {
    if (applicationId) {
      await updateApplicationState({
        variables: {
          id: applicationId,
          set: {
            WorkflowState: ApplicationWorkflowState.Withdrawn
          }
        },
        refetchQueries: [
          namedOperations.Query.getAdminApplication,
          namedOperations.Query.getApplication
        ],
        onCompleted: () => {
          createActionNote({
            variables: {
              input: {
                Action: ActionNoteAction.WithdrawApplication,
                EntityId: applicationId,
                Entity: ActionNoteEntity.Application,
                UserId: user?.Id,
                Notes
              }
            },
            onCompleted: closeModal
          })
        }
      })
    }
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Formik
        enableReinitialize
        initialValues={{
          Notes: ''
        }}
        onSubmit={handleWithdrawApplication}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Heading3 className='mb-5'>
                Withdraw application #{applicationData?.Application?.Reference}
                {isAdmin
                  ? ` on behalf of ${applicationData?.Application?.Planter?.Name}`
                  : ''}
              </Heading3>
              <Heading5 className='mb-5'>
                {applicationData?.Application?.Name}
              </Heading5>
              {isAdmin ? (
                <Paragraph>
                  Withdrawing an application means the application is now closed
                  and will not be matched to funding. The planter will receive
                  an email letting them know this action has taken place.
                </Paragraph>
              ) : (
                <>
                  <Paragraph>
                    Withdrawing an application means it will not be considered
                    for funding and will be closed.
                  </Paragraph>
                  <Paragraph>
                    You can withdraw an application as long as it&apos;s pending
                    and is before the month of October.{' '}
                  </Paragraph>
                </>
              )}
              <FormField
                id='Notes'
                label='Add a brief reason for withdrawing this application'
                type={FormFieldTypes.textarea}
              />
              <Paragraph className='font-bold'>
                Are you sure you want to withdraw this application
                {isAdmin ? ' on behalf of this planter' : ''}?
              </Paragraph>

              <HStack className='mt-10 flex justify-between'>
                <Button
                  className='mr-2'
                  onClick={closeModal}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button disabled={isSubmitting} className='mr-2' type='submit'>
                  Yes, withdraw application
                </Button>
              </HStack>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
