import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HStack } from 'src/components/templates/HStack'

export type RemovePaymentMethodModalProps = {
  closeModal: () => void
}

export const RemovePaymentMethodModal = ({
  closeModal
}: RemovePaymentMethodModalProps) => {
  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Unable to remove/delete saved card</Heading3>
      <Paragraph>
        You have an active monthly donation linked to this card. To remove this
        card from your account, you can either
      </Paragraph>
      <ul className='list-styled black'>
        <li>update your existing payment method or,</li>
        <li>first cancel your monthly donation and then remove the card</li>
      </ul>

      <HStack className='mt-10 flex justify-between'>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Close
        </Button>
      </HStack>
    </div>
  )
}
