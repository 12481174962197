import { Button } from 'src/components/atoms/Button'
import { Heading3, Heading5, Paragraph } from 'src/components/atoms/Typography'
import { HStack } from 'src/components/templates/HStack'

export interface SubmitPlantingModalProps {
  plantingName?: string
  onSubmit?: () => void
  closeModal: () => void
}

export const SubmitPlantingModal = ({
  plantingName,
  onSubmit,
  closeModal
}: SubmitPlantingModalProps) => {
  const handleSubmitPlanting = () => {
    if (onSubmit) {
      onSubmit()
    }
    closeModal()
  }
  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Submit planting report</Heading3>
      <Heading5>{plantingName}</Heading5>
      <Paragraph>
        Once you submit, you won&apos;t be able to change this planting report.
      </Paragraph>
      <Paragraph>
        Are you sure you want to submit this planting report?
      </Paragraph>
      <HStack className='mt-10 flex justify-between'>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Cancel
        </Button>
        <Button className='mr-2' onClick={handleSubmitPlanting} type='submit'>
          Yes, submit report
        </Button>
      </HStack>
    </div>
  )
}
