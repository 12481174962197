import { useRouter } from 'next/router'

import { useGetMonitoringEventQuery } from 'src/config/generated/graphql'
import { formatDate } from 'src/utils/date'

import { truncateBreadcrumbText } from '../helper'

export const AdminViewPlantingMonitoringBreadcrumb = () => {
  const { eventId } = useRouter().query as { eventId: string }

  const { data: plantingData } = useGetMonitoringEventQuery({
    variables: { id: eventId }
  })

  const name = formatDate({
    format: 'DD MMM YYYY',
    date: plantingData?.MonitoringEvent[0]?.MonitoredOn!,
    isUtc: true
  })

  const breadcrumbText = truncateBreadcrumbText(name || 'Monitoring')

  return <span>{breadcrumbText}</span>
}
