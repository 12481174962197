import { Image } from '../Image'
import { ParagraphNoSpace } from '../Typography'

export type CardSwitchOption<T = string | number> = {
  title: string
  description: string
  value?: T
  imageUrl?: string
}

export type CardSwitchProps = {
  id: string
  options?: Array<CardSwitchOption>
  onChange: (e: React.ChangeEvent<any>) => void
  disabled?: boolean
  value?: string
}

const SwitchCard = ({
  id,
  value,
  disabled,
  isSelected,
  title,
  description,
  imageUrl,
  onSelect
}: CardSwitchOption & {
  id: string
  disabled?: boolean
  isSelected?: boolean
  onSelect: (ev: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <label
      htmlFor={`${id}_${value}`}
      className={`${
        isSelected ? 'bg-aquaSpring' : 'bg-white hover:bg-[#f5f5f5]'
      } border rounded-[5px] border-anotherGrey1 transition-colors duration-200 ease-in-out flex-1`}
      key={`${id}_${value}`}
    >
      <input
        id={`${id}_${value}`}
        className='peer sr-only'
        name={id}
        onChange={onSelect}
        type='radio'
        value={value}
        disabled={disabled}
        checked={isSelected}
      />
      <div className='flex flex-row gap-5 p-5 cursor-pointer'>
        {imageUrl ? (
          <Image
            alt={title}
            width={60}
            height={60}
            src={imageUrl}
            className='object-contain'
          />
        ) : null}
        <div className='flex flex-col flex-1 gap-[5px]'>
          <span className='text-shark text-[18px] leading-[24px] font-bold'>
            {title}
          </span>
          <ParagraphNoSpace>{description}</ParagraphNoSpace>
        </div>
      </div>
    </label>
  )
}

const CardSwitch = ({
  id,
  options,
  onChange,
  disabled,
  value
}: CardSwitchProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
  }

  if (!options) return null

  return (
    <div
      role='radiogroup'
      aria-labelledby={id}
      className='flex flex-col md:flex-row overflow-hidden gap-5'
    >
      {options.map(o => (
        <SwitchCard
          onSelect={handleOnChange}
          description={o.description}
          id={id}
          title={o.title}
          value={o.value}
          disabled={disabled}
          imageUrl={o.imageUrl}
          isSelected={value === o.value}
          key={`card_${o.value}`}
        />
      ))}
    </div>
  )
}

export default CardSwitch
