import { IconProps } from './types'

export const Expand = ({ className }: IconProps) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.49971 3.5L11.3996 0.600098M11.3996 0.600098H7.79961M11.3996 0.600098V4.2001M0.599609 11.4001L3.5 8.49971M0.599609 11.4001V7.8001M0.599609 11.4001H4.19961'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.5 8.49922L11.4004 11.3996M11.4004 11.3996L11.4004 7.79961M11.4004 11.3996L7.80039 11.3996M0.60039 0.599609L3.50078 3.5M0.60039 0.599609L4.20039 0.599609M0.60039 0.599609L0.60039 4.19961'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
