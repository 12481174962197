import { Form, Formik } from 'formik'
import numeral from 'numeral'
import { useMemo } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'
import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Currency } from 'src/components/atoms/Currency'
import { Heading3, Heading5, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useCancelSubscriptionMutation,
  useGetAccountSubscriptionDetailsQuery,
  useGetSubscriptionQuery
} from 'src/config/generated/graphql'
import { FormFieldTypes } from 'src/types/form'

import validationSchema, {
  CancelSubscriptionFormValues
} from './validationSchema'

export type CancelSubscriptionModalProps = {
  closeModal: () => void
}

export const CancelSubscriptionModal = ({
  closeModal
}: CancelSubscriptionModalProps) => {
  const { user } = useUser()

  const { data, loading } = useGetAccountSubscriptionDetailsQuery({
    variables: {
      funderId: user?.Account?.FunderId
    }
  })

  const { data: stripeSubscription, loading: isStripeSubscriptionLoading } =
    useGetSubscriptionQuery({
      variables: {
        accountId: user?.Account?.Id
      },
      skip: !user?.Account?.Id || !user.Account.StripeCustomerId
    })
  const [cancelSubscription] = useCancelSubscriptionMutation()

  const subscription = useMemo(() => {
    return data?.Subscription[0]
  }, [data])

  const handleClickCancel = (resetForm: () => void) => {
    resetForm()
    closeModal()
  }

  const handleCancelSubscription = async (
    values: CancelSubscriptionFormValues
  ) => {
    await cancelSubscription({
      variables: {
        input: {
          Id: subscription?.Id,
          Reason: values.Reason
        }
      },
      onError: () => {
        notify({
          type: NotificationType.error,
          message:
            'We could not cancel your subscription, please get in touch if the problem persists.'
        })
      },
      onCompleted: async () => {
        closeModal()
        notify({
          type: NotificationType.success,
          message:
            "Your monthly donation payment was cancelled successfully. You'll get a confirmation email."
        })
      },
      refetchQueries: [namedOperations.Query.getAccountSubscriptionDetails]
    })
  }

  if (
    (!subscription && !loading) ||
    (!stripeSubscription && !isStripeSubscriptionLoading)
  ) {
    return (
      <div className='w-full sm:w-[520px]'>
        <Heading3>Cancel subscription</Heading3>
        <Heading5>Error</Heading5>
        <Paragraph>
          We could not find an active subscription on your account.
        </Paragraph>
        <HStack className='mt-10 flex justify-between'>
          <Button className='mr-2' onClick={closeModal} variant='outlined'>
            Close
          </Button>
        </HStack>
      </div>
    )
  }

  return (
    <div className='w-[520px]'>
      <Heading3 className='mb-5'>
        Do you want to stop and cancel your monthly donation? No worries.
      </Heading3>
      <span className='block font-medium'>
        Number of trees: {numeral(subscription?.Quantity).format('0,0')}
      </span>
      <span className='block font-medium mb-5'>
        Monthly amount:{' '}
        <Currency
          amount={stripeSubscription?.subscription?.Subscription?.TotalAmount!}
        />
      </span>
      <Formik
        onSubmit={handleCancelSubscription}
        validateOnMount
        initialValues={{
          Reason: ''
        }}
        validationSchema={validationSchema}
      >
        {({ resetForm, isSubmitting, isValid }) => (
          <Form>
            <FormField
              label=''
              description='Please tell us a bit more about why you’d like to cancel your donation.'
              type={FormFieldTypes.textarea}
              maxCharacters={150}
              id='Reason'
            />
            <Paragraph>Are you sure you want to cancel?</Paragraph>
            <HStack className='mt-10 flex justify-between'>
              <Button
                className='mr-2'
                onClick={() => handleClickCancel(resetForm)}
                variant='outlined'
              >
                No, keep it active
              </Button>
              <Button
                className='mr-2'
                type='submit'
                disabled={isSubmitting || !isValid}
              >
                Yes, continue to cancel
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </div>
  )
}
