import * as Yup from 'yup'

import { MembershipRoleType } from 'src/types/account'

const validationSchema = Yup.object({
  Role: Yup.number().oneOf(Object.values(MembershipRoleType).map(Number))
})

export type ChangeUserRoleFormValues = Yup.InferType<typeof validationSchema>

export default validationSchema
