import { PortableTextMarkComponentProps } from '@portabletext/react'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { ImageProps as NextImageProps } from 'next/image'
import { useEffect, useState } from 'react'

import { ButtonLink } from 'src/components/atoms/Link'
import { Heading4, Paragraph } from 'src/components/atoms/Typography'

import { getSanityImageUrl } from 'src/utils/sanity'

const DEFAULT_IMG_SRC = '/img/default.png'

const ActionBlockMask = ({ url }: { url: string }) => {
  return (
    <svg
      viewBox='0 0 800 240'
      fill='none'
      height={240}
      width={800}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      className='absolute -z-10 hidden md:block right-0'
    >
      <image
        style={{
          clipPath: 'url("#mask")'
        }}
        xlinkHref={url}
        x={400}
      />
      <defs>
        <clipPath id='mask'>
          <path d='M 476.58429,7.3679688e-4 A 275.1683,159.24207 0 0 0 441.65656,77.346437 a 275.1683,159.24207 0 0 0 36.00781,77.958983 141.05264,130.23727 0 0 0 -69.10156,84.69532 h 391.4375 V 7.3679688e-4 Z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const CtaBlock = ({
  value
}: PortableTextMarkComponentProps<{
  _type: 'ctaBlock'
  title: string
  intro: string
  backgroundImage: SanityImageSource
  link: {
    text: string
    url: string
  }
}>) => {
  const [imgSrc, setImgSrc] = useState<NextImageProps['src']>()
  useEffect(() => {
    try {
      if (!value?.backgroundImage) setImgSrc(DEFAULT_IMG_SRC)
      else {
        const url = getSanityImageUrl(value.backgroundImage, 800, 240)
        setImgSrc(url)
      }
    } catch (error) {
      setImgSrc(DEFAULT_IMG_SRC)
    }
  }, [value?.backgroundImage])

  return (
    <div className='z-0 my-5 md:my-10 flex relative bg-mostlyGreen text-white md:rounded-[10px] rounded-md overflow-hidden h-auto md:h-[240px]'>
      <ActionBlockMask url={imgSrc as string} />
      <div className='flex flex-col m-5 md:m-10 bg-transparent items-start justify-center'>
        <div className='gap-2 flex flex-col items-start w-full md:w-1/2 '>
          <Heading4 className='text-white !m-0'>{value?.title}</Heading4>
          <Paragraph className='font-normal'>{value?.intro}</Paragraph>
        </div>
        <ButtonLink size='large' href={value?.link.url!}>
          {value?.link.text}
        </ButtonLink>
      </div>
    </div>
  )
}
