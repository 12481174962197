import debounce from 'lodash.debounce'
import { useEffect, useState } from 'react'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'

import { DefaultInput } from 'src/components/atoms/DefaultInput'

import {
  useGetAccountLazyQuery,
  useListAdminAccountsLazyQuery
} from 'src/config/generated/graphql'
import { AdminListAccountType } from 'src/types/account'
import { FormFieldTypes } from 'src/types/form'

import { AccountOption } from './types'

export interface AccountSelectProps {
  id: string
  className?: string
  onChange: (newValue: SingleValue<AccountOption>) => void
  value?: string
  disabled?: boolean
}

export const AccountSelect = ({
  id,
  className = '',
  onChange,
  value,
  disabled = false
}: AccountSelectProps) => {
  const [initialLabelText, setInitialLabelText] = useState<string>()

  const [listAdminAccounts] = useListAdminAccountsLazyQuery()
  const [getAccount] = useGetAccountLazyQuery({
    onCompleted: data => {
      if (data?.account) {
        const { Name, Email, Id } = data.account
        setInitialLabelText(`${Name} (${Email})`)
        onChange({
          name: Name,
          email: Email,
          value: Id,
          label: `${Name} (${Email})`
        })
      }
    }
  })

  // Note: no dependencies as we only want to fire this onMount, rather than whenever value changes
  useEffect(() => {
    if (value) {
      getAccount({ variables: { id: value } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const transformAccounts = (accounts: AdminListAccountType[]) => {
    return accounts.map(p => ({
      label: `${p.Name} (${p.Email})`,
      value: p.Id,
      name: p.Name,
      email: p.Email
    }))
  }

  const handleLoadAccounts = debounce(
    async (newValue: string, callback: (options: AccountOption[]) => void) => {
      const queryString = `%${newValue}%`

      const { data } = await listAdminAccounts({
        variables: {
          // Hardcoded to first 10 results that match the string
          offset: 0,
          where: {
            _or: [
              { Name: { _ilike: queryString } },
              { Email: { _ilike: queryString } }
            ]
          }
        }
      })

      const values = transformAccounts(data?.Account || [])
      callback(values)

      return values
    },
    500
  )

  return initialLabelText ? (
    <DefaultInput
      id={id}
      disabled
      value={initialLabelText}
      className='mb-0'
      type={FormFieldTypes.text}
    />
  ) : (
    <AsyncSelect
      isDisabled={disabled}
      aria-label='Select account'
      className={`mt-2 ${className}`}
      defaultOptions
      loadOptions={handleLoadAccounts}
      onChange={onChange}
    />
  )
}
