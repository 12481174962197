export enum ActionNoteAction {
  ChangeTreeNumbers = 'ChangeTreeNumbers',
  WithdrawApplication = 'Withdrawn',
  RemoveUser = 'RemoveUser',
  DeleteProject = 'DeleteProject'
}

export enum ActionNoteEntity {
  Application = 'Application',
  Account = 'Account',
  Project = 'Project'
}
