import { hasCookie } from 'cookies-next'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'

import { useConsent } from 'src/providers/ConsentProvider/ConsentProvider'

import { Button } from 'src/components/atoms/Button'
import { IconButton } from 'src/components/atoms/IconButton'
import { InternalLink } from 'src/components/atoms/Link'
import { Paragraph, SpanDisplay } from 'src/components/atoms/Typography'

import { privacyPolicyPath } from 'src/config/paths'

export const ConsentBanner = () => {
  const [hideConsent, setHideConsent] = useState(true)
  const { allowCookies, denyCookies } = useConsent()

  useEffect(() => {
    const consentHidden =
      hasCookie('localConsent') ||
      sessionStorage.getItem('consent-dismissed') === 'true'
    setHideConsent(consentHidden)
  }, [])

  const handleAllowCookies = () => {
    setHideConsent(true)
    allowCookies()
  }

  const handleClosePopup = () => {
    sessionStorage.setItem('consent-dismissed', 'true')
    setHideConsent(true)
  }

  const handleDenyCookies = () => {
    setHideConsent(true)
    denyCookies()
  }

  if (hideConsent) return null

  return (
    <div
      className={`fixed w-full bottom-0 p-[30px] lg:px-[110px] lg:py-[30px] bg-alto z-[999] ${
        hideConsent ? 'hidden' : ''
      }`}
    >
      <div className='flex justify-between mb-5'>
        <SpanDisplay className='text-xl mb-[unset]'>Cookies</SpanDisplay>
        <div>
          <IconButton
            icon={MdClose}
            size='small'
            className='text-current'
            onClick={handleClosePopup}
            ariaLabel='Close consent'
          />
        </div>
      </div>
      <div className='flex flex-col xl:flex-row align-middle justify-between'>
        <Paragraph className='mb-[unset]'>
          Trees That Count uses cookies to ensure the best experience on our
          website.{' '}
          <InternalLink href={privacyPolicyPath}>Learn more</InternalLink>.
        </Paragraph>
        <div className='flex gap-x-[10px] justify-end mt-5 xl:mt-0'>
          <Button onClick={handleDenyCookies} color='white' size='small'>
            Deny all
          </Button>
          <Button onClick={handleAllowCookies} color='primary' size='small'>
            Allow all
          </Button>
        </div>
      </div>
    </div>
  )
}
