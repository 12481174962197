import { IconProps } from './types'

export const BulkGift = ({ className }: IconProps) => {
  return (
    <svg
      width='33'
      height='38'
      viewBox='0 0 33 38'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.5314 26.114H1.29999M10.4422 36.4848L9.81999 17.4459V13.2221M9.81999 13.2221C10.9309 12.4036 13.5736 10.8366 15.2574 11.1173C17.3622 11.4681 17.6253 11.8189 17.3622 12.3451C16.9237 13.0467 15.8713 15.59 9.81999 13.2221ZM9.81999 13.2221C10.7555 11.9066 12.4159 8.71432 11.574 6.46919C10.5216 3.66279 8.76759 6.03069 8.67989 6.81999C8.59219 7.6093 7.62749 11.4681 9.81999 13.2221ZM9.81999 13.2221C9.14762 13.4852 7.66257 14.0114 7.10129 14.0114C6.39969 14.0114 3.33018 13.1344 3.41788 11.1173C3.48804 9.50358 5.08418 9.97715 5.87348 10.4157C6.89665 11.088 9.11839 12.5907 9.81999 13.2221Z'
        stroke='currentColor'
        strokeWidth='1.67686'
      />
      <path
        d='M0.962219 16.9055L1.66382 36.5503M19.2457 16.9055L19.7212 36.1908C19.726 36.3879 19.5676 36.5503 19.3705 36.5503H1.42906'
        stroke='currentColor'
        strokeWidth='1.67686'
        strokeLinecap='round'
      />
      <path
        d='M1.35924 16.9214L19.1162 17.0808'
        stroke='currentColor'
        strokeWidth='2.1048'
        strokeLinecap='round'
      />
      <path
        d='M19.0272 5.20446C19.0946 5.05035 19.1624 4.89625 19.2294 4.74515C19.5734 3.96968 19.8713 3.33096 19.9917 3.1344C20.0983 2.96027 20.1967 2.88679 20.2536 2.86278C20.2782 2.85241 20.2984 2.84935 20.3197 2.85068C20.3229 2.85088 20.3266 2.85121 20.3306 2.85173C20.323 2.89111 20.3107 2.9366 20.2939 2.98616C20.2701 3.05595 20.2416 3.12108 20.2167 3.17069C19.9462 3.71035 19.5067 4.45229 19.0796 5.12238C19.0621 5.14996 19.0446 5.17732 19.0272 5.20446Z'
        stroke='currentColor'
        strokeWidth='1.21154'
      />
      <path
        d='M20.9008 7.50133C21.1284 7.29557 21.3629 7.085 21.591 6.88218C22.2587 6.28847 22.8252 5.80342 23.0287 5.6657C23.2105 5.54272 23.3437 5.51153 23.418 5.51354C23.451 5.51444 23.4767 5.52152 23.5014 5.53462C23.5122 5.54036 23.5256 5.54881 23.5412 5.56147C23.5136 5.6043 23.4745 5.6535 23.426 5.7051C23.3717 5.76298 23.3147 5.81406 23.2682 5.85166C22.7718 6.25278 22.0252 6.774 21.3225 7.23125C21.177 7.32594 21.0357 7.41639 20.9008 7.50133Z'
        stroke='currentColor'
        strokeWidth='1.21154'
      />
      <path
        d='M22.565 9.71785C22.7109 9.66542 22.8567 9.61334 23.0002 9.56245C23.8329 9.26721 24.5337 9.03252 24.7709 8.98064C24.9867 8.93342 25.1093 8.9556 25.1581 8.97678C25.1768 8.9849 25.1844 8.99227 25.1903 8.99969C25.1905 9.00005 25.1909 9.00046 25.1912 9.00089C25.0873 9.07371 24.9332 9.13821 24.8103 9.17569C24.216 9.35707 23.3608 9.55724 22.565 9.71785Z'
        stroke='currentColor'
        strokeWidth='1.21154'
      />
    </svg>
  )
}
