import { ClipLoader } from 'react-spinners'

interface LoadingSpinnerProps {
  children?: any
  isLoading: boolean
  color?: string
  size?: number
}

export const LoadingSpinner = ({
  children,
  isLoading,
  color,
  size
}: LoadingSpinnerProps) => {
  return isLoading ? (
    <div className='flex flex-col justify-center h-full'>
      <ClipLoader
        color={color}
        cssOverride={{ display: 'block' }}
        className='m-auto'
        size={size}
      />
    </div>
  ) : (
    children
  )
}
