import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { Heading3, Heading4, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useCreateAccountUserInviteMutation,
  useGetInviteByIdQuery
} from 'src/config/generated/graphql'
import { FormFieldTypes } from 'src/types/form'

import validationSchema, { ResendInviteFormValues } from './validationSchema'

const INVITE_EXPIRATION_DAYS = 7

type ResendInviteModalProps = {
  closeModal: () => void
  existingInviteId?: string
}

const ResendInviteModal = ({
  closeModal,
  existingInviteId
}: ResendInviteModalProps) => {
  const [createAccountUserInvite] = useCreateAccountUserInviteMutation()

  const { data, loading } = useGetInviteByIdQuery({
    variables: {
      id: existingInviteId
    },
    fetchPolicy: 'network-only'
  })

  const initialValues = useMemo<ResendInviteFormValues>(() => {
    return {
      Email: data?.Invite_by_pk?.RecipientEmail!,
      RecipientFirstname: data?.Invite_by_pk?.RecipientFirstname!,
      RecipientLastname: data?.Invite_by_pk?.RecipientLastname!,
      Message: data?.Invite_by_pk?.Message!,
      AccountId: data?.Invite_by_pk?.Account.Id
    }
  }, [data?.Invite_by_pk])

  const handleInviteSubmit = async (values: ResendInviteFormValues) => {
    createAccountUserInvite({
      variables: {
        email: values.Email,
        message: values.Message,
        recipientFirstname: values.RecipientFirstname,
        recipientLastname: values.RecipientLastname,
        accountId: values.AccountId,
        expiresAt: dayjs().add(INVITE_EXPIRATION_DAYS, 'days').toString(),
        code: uuidv4()
      },
      refetchQueries: [namedOperations.Query.listAccountMembershipsAndInvites],
      onCompleted: response => {
        if (response.insert_Invite_one?.Id) {
          closeModal()
          notify({
            message: `Your invitation to ${values.RecipientFirstname} to join this account has been sent.  Their invitation link will expire in ${INVITE_EXPIRATION_DAYS} days.`,
            type: NotificationType.success,
            duration: 2000
          })
        }
      }
    })
  }

  if (loading) return null

  return (
    <div className='w-full sm:w-[520px] overflow-y-auto'>
      <Heading3 className='!text-[28px] !leading-[39px] !font-semibold'>
        Resend invite
      </Heading3>
      <Paragraph className='!text-[16px] !leading-[24px] !font-medium'>
        Resend the invite to the new user to this account. Make sure you&apos;ve
        communicated with them they will receive an email.
      </Paragraph>
      <Formik
        onSubmit={handleInviteSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className='w-full'>
              <Heading4 className='!text-[20px] !leading-[26px] !font-bold'>
                Contact
              </Heading4>
              <FormField
                label='First name'
                placeholder='First name'
                type={FormFieldTypes.text}
                id='RecipientFirstname'
              />
              <FormField
                label='Last name'
                placeholder='Last name'
                type={FormFieldTypes.text}
                id='RecipientLastname'
              />
              <FormField
                label='Email address'
                placeholder='Email'
                type={FormFieldTypes.email}
                id='Email'
              />
            </div>
            <div>
              <Heading4 className='!text-[20px] !leading-[26px] !font-bold'>
                Message
              </Heading4>
              <FormField
                label=''
                placeholder='Message'
                type={FormFieldTypes.textarea}
                rows={3}
                id='Message'
              />
            </div>
            <Divider />
            <HStack className='mt-10 flex justify-between'>
              <Button className='mr-2' onClick={closeModal} variant='outlined'>
                Cancel
              </Button>
              <Button
                className='mr-2'
                type='submit'
                disabled={isSubmitting || !isValid}
              >
                Send invite
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ResendInviteModal
