import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'

import { Option } from 'src/types/form'

export interface RadioProps extends Option {
  checked: boolean
  id: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Radio = ({
  checked,
  id,
  label,
  selectedContent,
  selectedContentAwlaysVisible = true,
  onBlur,
  onChange,
  disabled,
  value
}: RadioProps) => {
  return (
    <label className='inline-flex items-start ml-[-11px] mr-4 cursor-pointer'>
      <span className='inline-flex items-center justify-center relative box-border bg-transparent outline-current outline-none outline-0 border-none p-0 cursor-pointer select-none appearance-none no-underline m-[9px] text-ivoryBlack/75 rounded-[50%] transition-colors duration-200 ease-in-out focus-within-shadow'>
        <input
          className='sr-only'
          name={id}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          type='radio'
          value={value?.toString()}
        />
        <span className='flex relative'>
          {checked ? (
            <MdRadioButtonChecked
              className={`select-none w-6 h-6 inline-block fill-current flex-shrink-0 transition-colors duration-200 ease-in-out text-primary ${
                disabled ? 'opacity-40 cursor-default' : ''
              }`}
            />
          ) : (
            <MdRadioButtonUnchecked
              className={`select-none w-6 h-6 inline-block fill-current flex-shrink-0 transition-colors duration-200 ease-in-out ${
                disabled ? 'opacity-40 cursor-default' : ''
              }`}
            />
          )}
        </span>
      </span>
      <span
        className={`flex-1 m-0 font-normal text-base leading-6 ${
          disabled ? 'opacity-40 cursor-default' : ''
        }`}
      >
        <div className='pt-2'>
          <span className='font-medium'>{label}</span>
          {checked || selectedContentAwlaysVisible ? (
            <div className='form-description-text text-anotherGrey2 text-base'>
              {selectedContent}
            </div>
          ) : null}
        </div>
      </span>
    </label>
  )
}

export interface RadioGroupProps {
  children: React.ReactNode
  id: string
}

export const RadioGroup = ({ children, id }: RadioGroupProps) => {
  return (
    <div
      className='flex flex-col flex-wrap'
      role='radiogroup'
      aria-labelledby={id}
    >
      {children}
    </div>
  )
}
