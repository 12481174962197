import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useDeleteAccountInviteMutation
} from 'src/config/generated/graphql'

type DeleteInviteModalProps = {
  closeModal: () => void
  invitationId?: string
}

const DeleteInviteModal = ({
  closeModal,
  invitationId
}: DeleteInviteModalProps) => {
  const [deleteAccountInvite] = useDeleteAccountInviteMutation()

  const handleDeleteInviteSubmit = async () => {
    deleteAccountInvite({
      variables: {
        id: invitationId
      },
      refetchQueries: [namedOperations.Query.listAccountMembershipsAndInvites],
      onCompleted: () => {
        notify({
          message: `Invitation sucessfully deleted`,
          type: NotificationType.success,
          duration: 2000
        })
        closeModal()
      }
    })
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3 className='!text-[28px] !leading-[39px] !font-semibold'>
        Delete invite
      </Heading3>
      <Paragraph className='!text-[16px] !leading-[24px] !font-medium'>
        Are you sure you want to delete this invite?
      </Paragraph>
      <Divider />
      <HStack className='flex justify-between'>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Cancel
        </Button>
        <Button
          onClick={handleDeleteInviteSubmit}
          className='mr-2'
          type='submit'
        >
          Delete invite
        </Button>
      </HStack>
    </div>
  )
}

export default DeleteInviteModal
