import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'
import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  useDeleteProjectMutation,
  useGetProjectQuery
} from 'src/config/generated/graphql'
import { listProjectsPath } from 'src/config/paths'
import { FormFieldTypes } from 'src/types/form'

import { DeleteProjectFormValues } from './types'
import validationSchema from './validationSchema'

export interface DeleteProjectModalProps {
  projectId?: string
  closeModal: () => void
}

export const DeleteProjectModal = ({
  projectId,
  closeModal
}: DeleteProjectModalProps) => {
  const { user } = useUser()
  const router = useRouter()
  const planterId = user?.Account?.PlanterId

  const { data: projectData } = useGetProjectQuery({
    variables: {
      id: projectId,
      planterId
    },
    skip: !planterId
  })

  const [deleteProject] = useDeleteProjectMutation()

  const handleDeleteProject = (values: DeleteProjectFormValues) => {
    deleteProject({
      variables: {
        input: {
          ProjectId: projectId!,
          Reason: values.Reason
        }
      },
      onCompleted: async response => {
        if (response.delete_project_by_id?.ProjectId) {
          notify({
            message: 'Successfully deleted project',
            type: NotificationType.success
          })
          closeModal()
          await router.replace(listProjectsPath)
        } else {
          notify({
            message: 'We could not delete your project',
            type: NotificationType.error
          })
          closeModal()
        }
      }
    })
  }

  const project = useMemo(() => {
    if (projectData?.Project.length === 1) return projectData?.Project[0]
    return null
  }, [projectData])

  if (!project) return null

  return (
    <div className='w-full sm:w-[520px]'>
      <Formik
        initialValues={{
          Confirm: false,
          Reason: ''
        }}
        validateOnMount
        onSubmit={handleDeleteProject}
        validationSchema={validationSchema}
      >
        {({ isValid }) => {
          return (
            <Form>
              <Heading3 className='mb-5'>
                You&apos;re about to delete your project: {project.Name}
              </Heading3>
              <FormField
                type={FormFieldTypes.radioGroup}
                id='Reason'
                labelClassName='font-normal text-[16px] leading-[24px]'
                label='Please select a reason for deleting this project.'
                options={[
                  {
                    label: "We're not working on this project anymore",
                    value: 'NotRelevant'
                  },
                  {
                    label: 'I was just testing the website',
                    value: 'JustTesting'
                  },
                  {
                    label: 'No reason',
                    value: 'NoReason'
                  }
                ]}
              />
              <Paragraph>
                Deleting this project will be permanent and cannot be undone.
                Are you sure you want to delete this project?
              </Paragraph>
              <FormField
                type={FormFieldTypes.checkbox}
                className='mb-0'
                id='Confirm'
                label=''
                checkboxLabel='Yes, please delete this project'
                labelClassName='sr-only'
              />
              <Divider />
              <HStack className='flex justify-between'>
                <Button
                  className='mr-2'
                  onClick={closeModal}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button className='mr-2' type='submit' disabled={!isValid}>
                  Submit
                </Button>
              </HStack>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
