import { useRouter } from 'next/router'

import { useGetProjectByUriQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const ViewPublicProjectBreadcrumb = () => {
  const { uri } = useRouter().query as { uri: string }

  const { data: projectData } = useGetProjectByUriQuery({
    variables: { uri }
  })
  const name = projectData?.Project[0]?.Name || ''

  const breadcrumbText = truncateBreadcrumbText(name || 'Project')

  return <span>{breadcrumbText}</span>
}
