import { useEffect } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useGetAdminApplicationLazyQuery,
  useUpdateApplicationMutation
} from 'src/config/generated/graphql'
import { ApplicationWorkflowState } from 'src/types/application'

export interface ReturnApplicationToPendingModalProps {
  applicationId?: string
  closeModal: () => void
}

export const ReturnApplicationToPendingModal = ({
  applicationId,
  closeModal
}: ReturnApplicationToPendingModalProps) => {
  const [getApplication, { data: applicationData }] =
    useGetAdminApplicationLazyQuery()

  const [updateApplicationState] = useUpdateApplicationMutation({
    onCompleted: () =>
      notify({
        message: 'Successfully unapproved application',
        type: NotificationType.success
      }),
    refetchQueries: [
      namedOperations.Query.getAdminApplication,
      namedOperations.Query.listAdminApplicationsView
    ]
  })

  useEffect(() => {
    if (applicationId) {
      getApplication({ variables: { id: applicationId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const handleReturnApplicationToPending = () => {
    if (applicationId) {
      updateApplicationState({
        variables: {
          id: applicationId,
          set: {
            WorkflowState: ApplicationWorkflowState.Pending
          }
        },
        onCompleted: closeModal
      })
    }
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>
        Unapprove application #{applicationData?.Application?.Reference}
      </Heading3>

      <Paragraph>
        Are you sure you wish to unapprove this application?
      </Paragraph>
      <Paragraph>
        The application will return to pending status and no further action will
        take place.
      </Paragraph>
      <HStack>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Cancel
        </Button>
        <Button
          className='mr-2'
          onClick={handleReturnApplicationToPending}
          type='submit'
        >
          Confirm
        </Button>
      </HStack>
    </div>
  )
}
