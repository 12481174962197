import { Form, Formik } from 'formik'
import { useEffect } from 'react'

import { NotificationType, notify } from 'src/providers/NotificationProvider'
import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useCreateActionNoteMutation,
  useGetAdminApplicationLazyQuery,
  useUpdateApplicationCountsMutation
} from 'src/config/generated/graphql'
import { ActionNoteAction, ActionNoteEntity } from 'src/types/actionNotes'
import { ApplicationWorkflowState } from 'src/types/application'
import { FormFieldTypes } from 'src/types/form'

import { UpdateApplicationCountsFormValues } from './types'
import validationSchema, {
  UpdateApplicationCountsValues
} from './validationSchema'

export interface UpdateApplicationCountsModalProps {
  applicationId?: string
  closeModal: () => void
}

export const UpdateApplicationCountsModal = ({
  applicationId,
  closeModal
}: UpdateApplicationCountsModalProps) => {
  const [getApplication, { data: applicationData }] =
    useGetAdminApplicationLazyQuery()
  const [createActionNote] = useCreateActionNoteMutation()

  const { user } = useUser()

  const [updateApplicationCounts] = useUpdateApplicationCountsMutation({
    onCompleted: () => {
      notify({
        message: 'Successfully updated application',
        type: NotificationType.success
      })
      closeModal()
    },
    onError: response => {
      notify({ message: response.message, type: NotificationType.error })
    },
    refetchQueries: [
      namedOperations.Query.getAdminApplication,
      namedOperations.Query.listAdminApplicationsView
    ]
  })

  useEffect(() => {
    if (applicationId) {
      getApplication({ variables: { id: applicationId as string } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId])

  const handleClickCancel = (resetForm: () => void) => {
    resetForm()
    closeModal()
  }

  const handleUpdateApplicationCounts = async (
    values: UpdateApplicationCountsFormValues
  ) => {
    const applicationNote = `Changed number required from ${
      applicationData?.Application?.Required
    } to ${values.Required} ${
      applicationData?.Application?.WorkflowState ===
      ApplicationWorkflowState.Pending
        ? ''
        : ` and number approved from ${applicationData?.Application?.Approved} to ${values.Approved}.`
    }\n${values.Notes}`

    await updateApplicationCounts({
      variables: {
        input: {
          ApplicationId: applicationId,
          Approved: values?.Approved || undefined,
          Required: values?.Required || undefined
        }
      },
      onCompleted: () =>
        createActionNote({
          variables: {
            input: {
              Action: ActionNoteAction.ChangeTreeNumbers,
              Entity: ActionNoteEntity.Application,
              EntityId: applicationId,
              UserId: user?.Id,
              Notes: applicationNote
            }
          },
          onCompleted: closeModal,
          refetchQueries: [namedOperations.Query.getAdminApplication]
        })
    })
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Change number of trees</Heading3>
      <Paragraph>Change number of trees</Paragraph>
      <dl className='grid gap-y-1 mb-4'>
        <dt className='col-start-1 font-bold mr-4'>Application:</dt>
        <dd className='col-start-2'>
          {applicationData?.Application?.Reference}
        </dd>
        <dt className='col-start-1 font-bold mr-4'>Planter:</dt>
        <dd className='col-start-2'>
          {applicationData?.Application?.Planter?.Name}
        </dd>
        <dt className='col-start-1 font-bold mr-4'>Project:</dt>
        <dd className='col-start-2'>
          {applicationData?.Application?.Project?.Name}
        </dd>
        <dt className='col-start-1 font-bold mr-4'>Matched:</dt>
        <dd className='col-start-2'>{applicationData?.Application?.Matched}</dd>
      </dl>
      <Formik<UpdateApplicationCountsValues>
        initialValues={{
          CurrentApproved: applicationData?.Application?.Approved || 0,
          Approved: applicationData?.Application?.Approved || 0,
          CurrentRequired: applicationData?.Application?.Required || 0,
          Required: applicationData?.Application?.Required || 0,
          Notes: '',
          Matched: applicationData?.Application?.Matched || 0,
          WorkflowState: applicationData?.Application?.WorkflowState
        }}
        enableReinitialize
        onSubmit={handleUpdateApplicationCounts}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm, values }) => {
          return (
            <Form>
              <div className='flex space-x-2'>
                <FormField
                  id='CurrentRequired'
                  disabled
                  label='Required'
                  type={FormFieldTypes.number}
                />
                <FormField
                  id='Required'
                  label='Change required to'
                  type={FormFieldTypes.number}
                />
              </div>
              {applicationData?.Application?.WorkflowState ===
              ApplicationWorkflowState.Pending ? null : (
                <div className='flex space-x-2'>
                  <FormField
                    id='CurrentApproved'
                    disabled
                    label='Approved'
                    type={FormFieldTypes.number}
                  />
                  <FormField
                    id='Approved'
                    label='Change approved to'
                    type={FormFieldTypes.number}
                  />
                </div>
              )}
              <div>
                <FormField
                  id='Notes'
                  label='Add reason for changing tree numbers'
                  type={FormFieldTypes.textarea}
                />
              </div>
              <HStack>
                <Button
                  className='mr-2'
                  onClick={() => handleClickCancel(resetForm)}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    isSubmitting ||
                    (values.Approved === values.CurrentApproved &&
                      values.Required === values.CurrentRequired)
                  }
                  className='mr-2'
                  type='submit'
                >
                  Update
                </Button>
              </HStack>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
