import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { useGetPurchaseDetailByTokenQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const ViewFundingDetailBreadcrumb = () => {
  const { token } = useRouter().query as { token: string }

  const { data: projectData } = useGetPurchaseDetailByTokenQuery({
    fetchPolicy: 'network-only',
    variables: { token }
  })
  const reference = useMemo(
    () => projectData?.Purchase[0]?.Reference.toString(),
    [projectData?.Purchase]
  )

  const breadcrumbText = reference ? truncateBreadcrumbText(reference) : ''

  return <span>{breadcrumbText}</span>
}
