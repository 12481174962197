import debounce from 'lodash.debounce'
import { useEffect, useState } from 'react'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'

import { DefaultInput } from 'src/components/atoms/DefaultInput'

import {
  useListAdminRegistriesLazyQuery,
  useGetAdminRegistryViewLazyQuery
} from 'src/config/generated/graphql'
import { FormFieldTypes, Option } from 'src/types/form'
import { AdminListRegistryType } from 'src/types/registry'
import { formatDate } from 'src/utils/date'

export interface RegistrySelectProps {
  id: string
  className?: string
  onChange: (newValue: SingleValue<Option>) => void
  value?: string
}

export const RegistrySelect = ({
  id,
  className = '',
  onChange,
  value
}: RegistrySelectProps) => {
  const [initialLabelText, setInitialLabelText] = useState<string>()

  const buildLabel = (
    reference: number,
    name: string,
    accountName: string,
    email: string,
    closesOn?: string | null
  ) => {
    return `#${reference}: ${name} (${accountName}: ${email})${
      closesOn
        ? `- Closes ${formatDate({ date: closesOn, format: 'DD/MM/YYYY' })}`
        : ''
    }`
  }

  const [listAdminRegistries] = useListAdminRegistriesLazyQuery()
  const [getAdminRegistryView] = useGetAdminRegistryViewLazyQuery({
    onCompleted: data => {
      if (data?.RegistryView?.[0]) {
        const registry = data?.RegistryView?.[0]
        setInitialLabelText(
          buildLabel(
            registry.Reference!,
            registry.Name!,
            registry.Account?.Name!,
            registry.Account?.Email!,
            registry?.ClosesOn
          )
        )
      }
    }
  })

  // Note: no dependencies as we only want to fire this onMount, rather than whenever value changes
  useEffect(() => {
    if (value) {
      getAdminRegistryView({ variables: { id: value } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const transformRegistries = (registries: AdminListRegistryType[]) => {
    return registries.map(r => ({
      label: buildLabel(
        r.Reference,
        r.Name,
        r.Funder.Account?.Name!,
        r.Funder.Account?.Email!,
        r?.ClosesOn
      ),
      value: r.Id
    }))
  }

  const handleLoadRegistries = debounce(
    async (newValue: string, callback: (options: Option[]) => void) => {
      const queryString = `%${newValue}%`

      const { data } = await listAdminRegistries({
        variables: {
          // Hardcoded to first 10 results that match the string
          offset: 0,
          where: {
            _and: [
              { IsClosed: { _eq: false } },
              {
                _or: [
                  { Name: { _ilike: queryString } },
                  { Funder: { Account: { Name: { _ilike: queryString } } } },
                  { Funder: { Account: { Email: { _ilike: queryString } } } }
                ]
              }
            ]
          }
        }
      })

      const values = transformRegistries(data?.Registry || [])
      callback(values)

      return values
    },
    500
  )

  return initialLabelText ? (
    <DefaultInput
      id={id}
      disabled
      value={initialLabelText}
      className='mb-0'
      type={FormFieldTypes.text}
    />
  ) : (
    <AsyncSelect
      aria-label='Select registry'
      className={`mt-2 ${className}`}
      defaultOptions
      loadOptions={handleLoadRegistries}
      onChange={onChange}
    />
  )
}
