import { useRouter } from 'next/router'

import { useGetAdminPlantingQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const AdminViewPlantingBreadcrumb = () => {
  const { plantingId } = useRouter().query as { plantingId: string }

  const { data: plantingData } = useGetAdminPlantingQuery({
    variables: { id: plantingId }
  })

  const name = plantingData?.Planting?.Name
  const breadcrumbText = truncateBreadcrumbText(name || 'Planting')

  return <span>{breadcrumbText}</span>
}
