import { useMemo } from 'react'

import { Select, SelectProps } from 'src/components/atoms/Select'

import { useListRegionsForFormQuery } from 'src/config/generated/graphql'
import { Option } from 'src/types/form'

import { buildRegionOptions } from './helpers'

export interface RegionSelectProps extends SelectProps {
  nullOption?: Option
  regionsOnly?: boolean
}

export const RegionSelect = ({
  regionsOnly = true,
  nullOption,
  ...props
}: RegionSelectProps) => {
  const { data: regionsData } = useListRegionsForFormQuery()

  const options: Option[] = useMemo(() => {
    if (regionsOnly)
      return buildRegionOptions(
        regionsData?.Region.filter(r => r.Type === 'Region'),
        nullOption
      )
    return buildRegionOptions(regionsData?.Region, nullOption)
  }, [regionsOnly, regionsData?.Region, nullOption])

  return <Select {...props} options={options} />
}
