import { LinkOption } from '../types'
import { MobileAccountMenu } from './MobileAccountMenu'

export interface MobileNavigationProps {
  className?: string
  links: LinkOption[]
}

export const MobileNavigation = ({
  className = '',
  links
}: MobileNavigationProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={e => {
        e.stopPropagation()
      }}
      className={`sm:min-w-[340px] sm:max-w-[340px] max-h-[calc(100%_-_80px)] overflow-auto absolute bg-white right-0 w-full top-[80px] z-[100] shadow-2xl ${className}`}
    >
      <MobileAccountMenu links={links} />
    </div>
  )
}
