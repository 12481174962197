import { useRouter } from 'next/router'

import { useGetAdminUserQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const AdminViewAccountUserBreadcrumb = () => {
  const { userId } = useRouter().query as { userId: string }

  const { data: userData } = useGetAdminUserQuery({
    variables: { userId }
  })

  const { Email } = userData?.User_by_pk || {}

  const breadcrumbText = truncateBreadcrumbText(Email || 'User')

  return <span>{breadcrumbText}</span>
}
