import { useRouter } from 'next/router'

import { useUser } from 'src/providers/UserProvider'

import { useGetApplicationQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const ViewApplicationBreadcrumb = () => {
  const { applicationId } = useRouter().query as { applicationId: string }
  const { user } = useUser()

  const { data: applicationData } = useGetApplicationQuery({
    variables: { id: applicationId, planterId: user?.Account?.PlanterId }
  })

  const name = `${applicationData?.Application[0]?.Reference}`
  const breadcrumbText = truncateBreadcrumbText(name || 'Tree request')

  return <span>{breadcrumbText}</span>
}
