import { IconProps } from './types'

export const Monthly = ({ className }: IconProps) => {
  return (
    <svg
      width='42'
      height='32'
      viewBox='0 0 42 32'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.4073 7.62764C13.7566 7.0112 11.9415 5.67558 10.7086 5.05914C9.052 4.23083 6.49631 2.90161 3.82507 4.44271C2.4902 5.21283 0.948361 7.62764 1.77028 9.99065C2.42781 11.8811 4.21127 15.0036 6.49631 18.0043C8.51685 20.4701 12.6401 25.5043 12.9689 25.9153C13.2977 26.3263 15.7704 29.2363 15.7704 29.2363'
        stroke='currentColor'
        strokeWidth='2.22078'
        strokeLinecap='round'
      />
      <path
        d='M16.0509 28.5862C17.6263 26.2917 19.8276 22.9685 21.3934 20.8807C22.7964 19.0101 24.3481 16.9553 26.1194 14.5108C27.8908 12.0664 29.0989 8.9629 28.6879 6.49715C28.277 4.0314 25.2975 0.435505 19.6468 3.62044C18.4825 4.20263 15.5741 6.04435 14.5878 7.606'
        stroke='currentColor'
        strokeWidth='2.22078'
        strokeLinecap='round'
      />
      <path
        d='M36.3033 12.7103C36.2142 12.7573 36.1248 12.7952 36.0535 12.8212L36.0535 12.8212C35.2786 13.1034 34.1461 13.428 33.0933 13.6929C33.05 13.7038 33.007 13.7146 32.9642 13.7252C33.1758 13.6354 33.3877 13.546 33.596 13.4588C34.6651 13.0113 35.5601 12.6563 35.864 12.574C36.1333 12.5011 36.2999 12.5204 36.379 12.5499C36.4131 12.5627 36.4361 12.5786 36.456 12.5999C36.459 12.6032 36.4623 12.6069 36.4659 12.6112C36.4213 12.6431 36.3665 12.6769 36.3033 12.7103Z'
        stroke='currentColor'
        strokeWidth='1.65517'
      />
      <path
        d='M32.6706 17.7642C33.0897 17.7734 33.5201 17.7842 33.9369 17.7967C35.157 17.8334 36.1747 17.8831 36.506 17.9372C36.8019 17.9856 36.9635 18.0796 37.0354 18.1513C37.0673 18.1831 37.0862 18.2143 37.0984 18.2504C37.1037 18.2662 37.1092 18.2872 37.1128 18.3144C37.0452 18.3311 36.9602 18.3433 36.8637 18.349L32.6706 17.7642ZM32.6706 17.7642C32.8843 17.8064 33.1095 17.8491 33.3428 17.8916C34.4696 18.0965 35.7002 18.2793 36.5694 18.3465L32.6706 17.7642Z'
        stroke='currentColor'
        strokeWidth='1.65517'
      />
      <path
        d='M32.2444 21.5272C32.4384 21.612 32.6321 21.697 32.8224 21.7811C33.9263 22.269 34.8425 22.6933 35.1267 22.8642C35.3854 23.0198 35.4863 23.1568 35.515 23.2236C35.5259 23.2493 35.5266 23.2637 35.5254 23.2766C35.5254 23.2772 35.5253 23.2779 35.5252 23.2786C35.3537 23.2535 35.1401 23.173 34.983 23.095C34.2225 22.7176 33.1854 22.1142 32.2444 21.5272Z'
        stroke='currentColor'
        strokeWidth='1.65517'
      />
    </svg>
  )
}
