import { useRouter } from 'next/router'

import { useGetAdminProjectQuery } from 'src/config/generated/graphql'

import { truncateBreadcrumbText } from '../helper'

export const AdminViewProjectDetailBreadcrumb = () => {
  const { projectId } = useRouter().query as { projectId: string }

  const { data: projectData } = useGetAdminProjectQuery({
    variables: { id: projectId }
  })
  const name = projectData?.Project[0]?.Name || ''

  const breadcrumbText = truncateBreadcrumbText(name || 'Project')

  return <span>{breadcrumbText}</span>
}
