import { Form, Formik } from 'formik'

import { NotificationType, notify } from 'src/providers/NotificationProvider'
import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useCreateActionNoteMutation,
  useDeleteUserMembershipMutation,
  useGetMembershipAndAccountsQuery
} from 'src/config/generated/graphql'
import { ActionNoteAction, ActionNoteEntity } from 'src/types/actionNotes'
import { FormFieldTypes } from 'src/types/form'

import validationSchema, {
  RemoveMembershipFormValues
} from './validationSchema'

const reasons = [
  'We have too many people on this account',
  'We have a duplicate account',
  'Left the organisation'
]

type RemoveMembershipModalProps = {
  closeModal: () => void
  membershipId?: string
  accountId?: string
}

const RemoveMembershipModal = ({
  closeModal,
  membershipId,
  accountId
}: RemoveMembershipModalProps) => {
  const [deleteMembership] = useDeleteUserMembershipMutation()
  const [createActionNote] = useCreateActionNoteMutation()

  const { data: membershipData } = useGetMembershipAndAccountsQuery({
    variables: {
      id: membershipId
    }
  })
  const { user } = useUser()

  const initialValues: RemoveMembershipFormValues = {
    Reason: '',
    Confirm: false
  }
  const handleUpdateRoleSubmit = async (values: RemoveMembershipFormValues) => {
    deleteMembership({
      variables: {
        input: { MembershipId: membershipId }
      },
      refetchQueries: [namedOperations.Query.listAccountMembershipsAndInvites],
      onCompleted: response => {
        if (response.delete_user_membership?.MembershipId) {
          createActionNote({
            variables: {
              input: {
                Action: ActionNoteAction.RemoveUser,
                Entity: ActionNoteEntity.Account,
                EntityId: accountId,
                Notes: `${values.Reason} - ${membershipData?.membership?.User.Email}`,
                UserId: user?.Id
              }
            },
            onCompleted: () => {
              closeModal()
              notify({
                message: `You successfully removed '${membershipData?.membership?.User.Email}' from this account`,
                type: NotificationType.success,
                duration: 2000
              })
            }
          })
        }
      }
    })
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3 className='!text-[28px] !leading-[39px] !font-semibold'>
        You&apos;re about to remove a user
      </Heading3>
      <Paragraph className='!text-[16px] !leading-[24px] !font-medium'>
        Please select a reason for removing this user.
      </Paragraph>
      <Formik
        onSubmit={handleUpdateRoleSubmit}
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <FormField
              label=''
              type={FormFieldTypes.radioGroup}
              id='Reason'
              options={reasons.map(r => ({
                value: r,
                label: r
              }))}
            />
            <Paragraph className='!text-[16px] !leading-[24px] !font-medium'>
              They will no longer be able to access this account. All their
              transactions will stay with this account.{' '}
            </Paragraph>
            <Paragraph className='!text-[16px] !leading-[24px] !font-medium'>
              Are you sure you want to remove this user?{' '}
            </Paragraph>
            <FormField
              id='Confirm'
              label=''
              type={FormFieldTypes.checkbox}
              checkboxLabel={`Yes, please remove this user (${membershipData?.membership?.User.Email})`}
            />
            <Divider />
            <HStack className='mt-10 flex justify-between'>
              <Button className='mr-2' onClick={closeModal} variant='outlined'>
                Cancel
              </Button>
              <Button
                className='mr-2'
                type='submit'
                disabled={isSubmitting || !isValid}
              >
                Remove user
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default RemoveMembershipModal
