import { useMemo } from 'react'
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdIndeterminateCheckBox
} from 'react-icons/md'

export interface CheckboxProps {
  ariaLabel?: string
  checked?: boolean
  disabled?: boolean
  id: string
  indeterminate?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
}

export const StandaloneCheckbox = ({
  ariaLabel = '',
  checked = false,
  disabled = false,
  id,
  indeterminate = false,
  onBlur,
  onChange,
  value
}: CheckboxProps) => {
  const renderIcon = () => {
    const fillClass = disabled ? 'fill-anotherGrey1' : 'fill-current'
    const className = `select-none w-6 h-6 inline-block flex-shrink-0 transition-colors duration-200 ease-in-out ${fillClass}`
    if (indeterminate) {
      return <MdIndeterminateCheckBox className={className} />
    }
    if (checked) {
      return <MdCheckBox className={className} />
    }
    return <MdCheckBoxOutlineBlank className={className} />
  }

  const checkboxColorClasses = useMemo(() => {
    if (disabled) {
      return 'text-anotherGrey1'
    }
    if (checked || indeterminate) {
      return 'text-primary'
    }
    return 'text-anotherGrey2'
  }, [checked, disabled, indeterminate])

  const cursorClasses = useMemo(() => {
    if (disabled) {
      return 'cursor-default'
    }
    return 'cursor-pointer'
  }, [disabled])

  return (
    <span
      className={`inline-flex items-center justify-center relative box-border bg-transparent outline-0 border-0 p-0 m-[9px] select-none align-middle appearance-none no-underline rounded-sm transition-colors duration-200 ease-in-out focus-within-shadow ${cursorClasses} ${checkboxColorClasses}`}
    >
      <input
        aria-label={ariaLabel}
        className='sr-only'
        checked={checked}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        type='checkbox'
        value={value}
        disabled={disabled}
      />
      {renderIcon()}
    </span>
  )
}
