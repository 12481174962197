import RCSwitch, {
  SwitchChangeEventHandler,
  SwitchClickEventHandler
} from 'rc-switch'
import 'rc-switch/assets/index.css'

export interface SwitchProps
  extends Omit<
    React.HTMLAttributes<HTMLButtonElement>,
    'onChange' | 'onClick'
  > {
  className?: string
  prefixCls?: string
  disabled?: boolean
  checkedChildren?: React.ReactNode
  unCheckedChildren?: React.ReactNode
  onChange?: SwitchChangeEventHandler
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>
  onClick?: SwitchClickEventHandler
  tabIndex?: number
  checked?: boolean
  defaultChecked?: boolean
  loadingIcon?: React.ReactNode
  style?: React.CSSProperties
  title?: string
}

export const Switch = (props: SwitchProps) => {
  return <RCSwitch {...props} />
}
