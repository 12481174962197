import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { ReactNode } from 'react'

import { Image } from 'src/components/atoms/Image'
import { HeadingDisplay, Subtitle } from 'src/components/atoms/Typography'
import {
  CmsCtaButton,
  CmsCtaButtonProps
} from 'src/components/molecules/CmsCtaButton'
import { Text, TextValue } from 'src/components/molecules/sanity/Content'

import { getSanityImageUrl } from 'src/utils/sanity'

import { Breadcrumbs } from '../Breadcrumbs'

export interface HeroProps {
  title: string
  subtitle?: TextValue | string
  subtitleComponent?: ReactNode
  ctaButtons?: CmsCtaButtonProps[] | JSX.Element
  showBreadcrumbs?: boolean
  // can be a simple URL or a Sanity image source object if coming from CMS
  image?: SanityImageSource | string
}

export const Hero = ({
  title,
  subtitle,
  ctaButtons,
  subtitleComponent,
  showBreadcrumbs,
  image
}: HeroProps) => {
  const isCMSImage = image && typeof image !== 'string'
  const src = isCMSImage ? getSanityImageUrl(image) : image

  return (
    <div className='w-screen px-full bg-secondary'>
      {showBreadcrumbs && <Breadcrumbs />}
      <div className='container mx-auto px-8 py-12 md:px-3'>
        <div className='md:flex md:items-center md:justify-items-stretch'>
          <div className='w-full'>
            <HeadingDisplay className='text-snow2'>{title}</HeadingDisplay>
            {subtitle ? (
              <Subtitle as='div' className='md:w-4/5 lg:w-2/3 font-medium'>
                {typeof subtitle === 'string' ? (
                  subtitle
                ) : (
                  <Text value={subtitle} />
                )}
              </Subtitle>
            ) : null}
            {subtitleComponent}
            <div className='flex items-start flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0'>
              {Array.isArray(ctaButtons)
                ? ctaButtons?.map((ctaButton, idx) => (
                    <CmsCtaButton
                      key={ctaButton.text}
                      {...ctaButton}
                      color={idx === 0 ? 'primary' : 'white'}
                    />
                  ))
                : ctaButtons}
            </div>
          </div>

          {src ? (
            <div className='w-3/4 mx-auto -mb-[250px] md:-mt-[50px] md:-mb-[50px] md:ml-8'>
              <Image
                alt=''
                src={src}
                height={600}
                width={600}
                objectFit='contain'
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
