import { Form, Formik } from 'formik'
import { useCallback } from 'react'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useGetAdminPurchaseByIdQuery,
  useUpdatePurchaseRecipientEmailMutation
} from 'src/config/generated/graphql'
import { FormFieldTypes } from 'src/types/form'

import { UpdatePurchaseRecipientEmailFormValues } from './types'
import validationSchema from './validationSchema'

export interface ApproveApplicationModalProps {
  purchaseId?: string
  closeModal: () => void
}

export const UpdatePurchaseRecipientEmail = ({
  purchaseId,
  closeModal
}: ApproveApplicationModalProps) => {
  const { data: purchase } = useGetAdminPurchaseByIdQuery({
    variables: {
      id: purchaseId
    }
  })

  const [updatePurchaseRecipientEmail] =
    useUpdatePurchaseRecipientEmailMutation()

  const handleUpdateRecipientEmail = useCallback(
    async (values: UpdatePurchaseRecipientEmailFormValues) => {
      if (purchaseId) {
        updatePurchaseRecipientEmail({
          refetchQueries: [
            namedOperations.Query.getAdminPurchaseById,
            namedOperations.Query.getAdminPurchase,
            namedOperations.Query.listAdminPurchases
          ],
          variables: {
            input: {
              Id: purchaseId,
              Email: values.Email
            }
          },
          onCompleted: closeModal
        })
      }
    },
    [closeModal, purchaseId, updatePurchaseRecipientEmail]
  )

  const handleClickCancel = (resetForm: () => void) => {
    resetForm()
    closeModal()
  }

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Update purchase recipient email</Heading3>
      <Paragraph>
        Enter the new email address for the recipient of this purchase:
      </Paragraph>
      <Formik
        initialValues={{
          Email: purchase?.Purchase_by_pk?.RecipientEmail || ''
        }}
        enableReinitialize
        onSubmit={handleUpdateRecipientEmail}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm }) => {
          return (
            <Form>
              <FormField id='Email' label='Email' type={FormFieldTypes.email} />
              <HStack>
                <Button
                  className='mr-2'
                  onClick={() => handleClickCancel(resetForm)}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button disabled={isSubmitting} className='mr-2' type='submit'>
                  Update
                </Button>
              </HStack>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
