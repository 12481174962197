import Image from 'next/image'
import { FaTimes, FaBars } from 'react-icons/fa'

import { IconButton } from 'src/components/atoms/IconButton'
import { InternalLink } from 'src/components/atoms/Link'

export interface MobileNavigationToggleProps {
  isOpen: boolean
  onSetIsOpen: (val: boolean) => void
}

export const MobileNavigationToggle = ({
  isOpen,
  onSetIsOpen
}: MobileNavigationToggleProps) => {
  const handleSetIsOpen = () => {
    onSetIsOpen(!isOpen)
  }

  return (
    <div className='mx-[20px] w-full justify-between items-center px-3 visible flex lg:hidden'>
      <InternalLink href='/'>
        <Image
          alt='Trees That Count logo'
          src='/img/logos/ttc-logo.svg'
          height={40}
          width={110}
        />
      </InternalLink>
      <div className='flex space-x-1 items-center'>
        <IconButton
          icon={isOpen ? FaTimes : FaBars}
          ariaLabel={isOpen ? 'Close menu' : 'Open menu'}
          onClick={handleSetIsOpen}
          size='small'
        />
      </div>
    </div>
  )
}
