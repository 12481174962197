import { Form, Formik } from 'formik'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3 } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useUpdateProjectMutation
} from 'src/config/generated/graphql'
import { FormFieldTypes, Option } from 'src/types/form'
import { ProjectFeaturedOn } from 'src/types/project'

import { UpdateProjectFeaturedFormValues } from './types'
import validationSchema from './validationSchema'

export interface FeatureProjectModalProps {
  projectId?: string
  featuredOn?: ProjectFeaturedOn
  closeModal: () => void
}

export const FeatureProjectModal = ({
  projectId,
  featuredOn,
  closeModal
}: FeatureProjectModalProps) => {
  const [updateProject] = useUpdateProjectMutation({
    refetchQueries: [namedOperations.Query.getAdminProject],
    onCompleted: () =>
      notify({
        message: 'Successfully updated project feature',
        type: NotificationType.success
      })
  })

  const handleUpdateProjectVisibility = async (
    values: UpdateProjectFeaturedFormValues
  ) => {
    if (projectId) {
      await updateProject({
        variables: {
          projectId,
          input: {
            Id: projectId,
            FeaturedOn:
              values.FeaturedOn === ProjectFeaturedOn.None
                ? null
                : values.FeaturedOn
          }
        },
        refetchQueries: [
          namedOperations.Query.getAdminProject,
          namedOperations.Query.getProjectNavState
        ],
        onCompleted: closeModal
      })
    }
  }

  const featureOptions: Array<Option> = [
    {
      label: 'Business',
      value: ProjectFeaturedOn.Business
    },
    {
      label: 'Home',
      value: ProjectFeaturedOn.Home
    },
    {
      label: 'All',
      value: ProjectFeaturedOn.All
    },
    {
      label: 'None',
      value: ProjectFeaturedOn.None
    }
  ]

  return (
    <div className='w-full sm:w-[520px]'>
      <Formik
        enableReinitialize
        initialValues={{
          FeaturedOn: featuredOn === null ? ProjectFeaturedOn.None : featuredOn
        }}
        onSubmit={handleUpdateProjectVisibility}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, dirty }) => {
          return (
            <Form>
              <Heading3 className='mb-5'>Feature project</Heading3>

              <FormField
                id='FeaturedOn'
                label=''
                checkboxLabel='Feature project on home page?'
                className='!mb-0'
                type={FormFieldTypes.radioGroup}
                options={featureOptions}
              />

              <HStack className='mt-10 flex justify-between'>
                <Button
                  className='mr-2'
                  onClick={closeModal}
                  variant='outlined'
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || !dirty}
                  className='mr-2'
                  type='submit'
                >
                  Save changes
                </Button>
              </HStack>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
