import { Option } from 'src/types/form'

export interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  id: string
  options?: Option[]
}

export const Select = ({
  className = '',
  disabled,
  id,
  onBlur,
  onChange,
  options,
  value
}: SelectProps) => {
  return (
    <div
      className={`form-input-wrapper ${
        disabled ? 'form-input-wrapper-disabled' : ''
      } ${className}`}
    >
      <select
        className='ttc-form-input'
        id={id}
        name={id}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      >
        {options?.map(opt => (
          <option key={opt.value} value={opt.value} disabled={opt.disabled}>
            {opt.label}
          </option>
        ))}
      </select>
    </div>
  )
}
