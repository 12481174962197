import { useEffect } from 'react'

import { useAuth } from 'src/providers/AuthProvider'

const EMAIL_VERIFICATION_POLLING_INTERVAL = 10000

export const EmailVerificationBanner = () => {
  const { refreshSession } = useAuth()
  useEffect(() => {
    const authSessionPollingInterval = setInterval(async () => {
      // rotate auth0 session using refresh tokens to detect when the email is verified
      await refreshSession()
    }, EMAIL_VERIFICATION_POLLING_INTERVAL)
    return () => {
      clearInterval(authSessionPollingInterval)
    }
  }, [refreshSession])

  return (
    <>
      Your email address is not yet verified. Please follow the link in your
      email.
    </>
  )
}
