import { IconProps } from './types'

export const AddPlants = ({ className }: IconProps) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.358 14.9778C12.2933 14.0891 10.819 14.0859 10.7602 14.9778C10.2991 22.04 9.87039 30.279 11.7627 37.16C12.1326 38.5044 14.2951 37.9662 13.9564 36.5846C12.2577 29.6496 12.8736 22.0719 12.358 14.9778Z'
        fill='currentColor'
      />
      <path
        d='M17.7041 13.337C18.8581 10.6552 18.2202 7.1872 15.0482 7.25353C14.5904 4.55234 12.2839 1.42297 9.30177 2.17849C5.02107 3.26349 4.94773 6.85878 6.0818 10.1941C4.03151 10.0458 2.26434 11.126 1.37455 13.3758C0.359108 15.9427 1.52015 18.3829 3.51328 19.8335C-0.295023 22.4813 3.58338 30.4571 9.07797 27.5866C9.76446 27.228 9.20685 26.4067 8.55973 26.5938C4.62471 27.7295 1.73855 21.3904 5.96964 20.8339C7.01258 20.6969 6.79633 19.3584 5.96964 19.0931C1.6555 17.7088 2.61702 10.2934 7.15387 12.2865C7.7503 12.5486 9.0084 12.1436 8.6115 11.27C7.64891 9.1512 6.34173 6.5541 8.3327 4.59278C10.9277 2.0372 13.226 6.16367 13.1807 8.25764C13.1672 8.89128 13.8672 9.23479 14.4167 9.10698C17.5935 8.36981 16.1984 12.5529 15.1507 13.6433C14.4302 14.394 15.1922 15.5205 16.1693 15.2595C18.5631 14.6188 18.9217 18.02 18.8705 19.6523C18.7907 22.1707 17.5261 24.1401 14.9759 24.5106C13.8149 24.6788 14.2053 26.2384 15.2407 26.2869C22.1379 26.6062 22.7085 14.5892 17.7041 13.337Z'
        fill='currentColor'
      />
      <path
        d='M38.6949 11.2284C37.6606 10.6827 36.546 10.9221 35.413 11.0154C35.2825 11.0267 35.1536 11.031 35.0231 11.0397C35.0091 10.0679 34.8807 9.12257 34.61 8.17616C34.3706 7.33922 32.893 7.63851 33.0995 8.53585C33.292 9.37279 33.3034 10.2356 33.2748 11.1017C32.4529 11.099 31.6327 11.0521 30.8028 10.9394C29.8488 10.8105 29.3182 12.1268 30.3714 12.3053C31.3189 12.4666 32.2609 12.5685 33.2111 12.6105C33.175 13.6805 33.1998 14.7369 33.5514 15.7399C33.7925 16.4286 34.9346 16.3061 34.9988 15.5733C35.0835 14.6064 35.036 13.6147 35.0215 12.6305C36.2483 12.637 37.6531 12.7109 38.6949 12.1365C39.0951 11.916 39.108 11.4463 38.6949 11.2284Z'
        fill='currentColor'
      />
      <path
        d='M33.0537 21.2108C32.0205 20.6365 30.8664 20.5998 29.6801 20.7098C29.6105 20.0271 29.5355 19.3487 29.473 18.6822C29.412 18.0307 28.306 18.0275 28.2494 18.6822C28.1863 19.4118 28.1448 20.1554 28.1307 20.9023C27.0015 21.0598 25.8766 21.2016 24.8277 21.0037C24.3526 20.9147 23.8198 21.5128 24.4168 21.7635C25.5385 22.2322 26.651 22.3966 27.8627 22.4123C27.9571 22.4134 28.0542 22.4161 28.1491 22.4177C28.2003 23.5237 28.3367 24.6217 28.6101 25.6775C28.7579 26.2497 29.7431 26.0998 29.8025 25.5481C29.9119 24.5327 29.8947 23.4908 29.8267 22.4473C30.7834 22.4624 31.7438 22.4662 32.6708 22.4139C33.3514 22.3751 33.7402 21.5921 33.0537 21.2108Z'
        fill='currentColor'
      />
      <path
        d='M24.6619 11.9837C24.9558 11.1613 25.0523 10.1815 25.0264 9.18385C25.9578 9.09649 26.8589 8.92068 27.6516 8.58149C28.2292 8.3345 28.0059 7.62375 27.4823 7.49379C26.6701 7.2921 25.7696 7.28185 24.8523 7.37461C24.7439 6.71832 24.5983 6.08684 24.4241 5.52924C24.1944 4.79422 22.8581 5.04444 23.0473 5.84848C23.1832 6.4255 23.2188 7.02516 23.2199 7.63184C22.3166 7.81842 21.4382 8.05948 20.6552 8.2768C20.0199 8.45314 20.2038 9.14286 20.8078 9.17252C21.552 9.20919 22.363 9.25018 23.1865 9.25557C23.1698 10.2004 23.2053 11.129 23.5348 11.9843C23.7085 12.4318 24.4958 12.4486 24.6619 11.9837Z'
        fill='currentColor'
      />
    </svg>
  )
}
