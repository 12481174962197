import { useRouter } from 'next/router'

import { trpc } from 'src/utils/trpc'

import { truncateBreadcrumbText } from '../helper'

export const NativeTreeSpeciesBreadcrumb = () => {
  const router = useRouter()

  const { slug } = router.query as { slug: string }

  const { data: nativeTree } = trpc.useQuery([
    'sanity.getNativeTreeSpeciesBySlug',
    { slug }
  ])

  const breadcrumbText = truncateBreadcrumbText(
    nativeTree?.name || 'Native tree'
  )

  return <span>{breadcrumbText}</span>
}
