import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { v4 as uuidv4 } from 'uuid'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { Heading3, Heading4, Paragraph } from 'src/components/atoms/Typography'
import { FormField } from 'src/components/molecules/FormField'
import { HStack } from 'src/components/templates/HStack'

import {
  namedOperations,
  useCreateAccountUserInviteMutation,
  useListAccountMembershipsLazyQuery
} from 'src/config/generated/graphql'
import { FormFieldTypes } from 'src/types/form'

import validationSchema, {
  InviteAccountUserFormValues
} from './validationSchema'

const INVITE_EXPIRATION_DAYS = 7

type InviteAccountUserModalProps = {
  closeModal: () => void
  accountId?: string
}

const InviteAccountUserModal = ({
  closeModal,
  accountId
}: InviteAccountUserModalProps) => {
  const [createAccountUserInvite] = useCreateAccountUserInviteMutation({
    refetchQueries: [namedOperations.Query.listAccountMembershipsAndInvites],
    awaitRefetchQueries: true
  })
  const [listAccountMemberships] = useListAccountMembershipsLazyQuery()

  const initialValues: InviteAccountUserFormValues = {
    Email: '',
    RecipientFirstname: '',
    RecipientLastname: '',
    Message:
      "Hi there, I'd like to give you access to manage my profile at Trees That Count."
  }
  const handleInviteSubmit = async (values: InviteAccountUserFormValues) => {
    await listAccountMemberships({
      variables: {
        accountId
      },
      fetchPolicy: 'network-only',
      onCompleted: async res => {
        if (
          res.Membership.findIndex(m => m.User.Email === values.Email) !== -1
        ) {
          notify({
            type: NotificationType.error,
            message: `The user with email address '${values.Email}' already has access to this account`
          })
          return
        }
        await createAccountUserInvite({
          variables: {
            email: values.Email,
            message: values.Message,
            recipientFirstname: values.RecipientFirstname,
            recipientLastname: values.RecipientLastname,
            accountId,
            expiresAt: dayjs().add(INVITE_EXPIRATION_DAYS, 'days').toString(),
            code: uuidv4()
          },
          onCompleted: response => {
            if (response.insert_Invite_one?.Id) {
              closeModal()
              notify({
                message: `Your invitation to ${values.RecipientFirstname} to join this account has been sent.  Their invitation link will expire in ${INVITE_EXPIRATION_DAYS} days.`,
                type: NotificationType.success
              })
            }
          }
        })
      }
    })
  }

  return (
    <div className='w-full sm:w-[520px] overflow-y-auto'>
      <Heading3 className='!text-[28px] !leading-[39px] !font-semibold'>
        Invite new user
      </Heading3>
      <Paragraph className='!text-[16px] !leading-[24px] !font-medium'>
        Add a new user to this account. Make sure you&apos;ve communicated with
        them they will receive an email.
      </Paragraph>
      <Formik
        onSubmit={handleInviteSubmit}
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className='w-full'>
              <Heading4 className='!text-[20px] !leading-[26px] !font-bold'>
                Contact
              </Heading4>
              <FormField
                label='First name'
                placeholder='First name'
                type={FormFieldTypes.text}
                id='RecipientFirstname'
              />
              <FormField
                label='Last name'
                placeholder='Last name'
                type={FormFieldTypes.text}
                id='RecipientLastname'
              />
              <FormField
                label='Email address'
                placeholder='Email'
                type={FormFieldTypes.email}
                id='Email'
              />
            </div>
            <div>
              <Heading4 className='!text-[20px] !leading-[26px] !font-bold'>
                Message
              </Heading4>
              <FormField
                label=''
                placeholder='Message'
                type={FormFieldTypes.textarea}
                rows={3}
                id='Message'
              />
            </div>
            <Divider />
            <HStack className='mt-10 flex justify-between'>
              <Button className='mr-2' onClick={closeModal} variant='outlined'>
                Cancel
              </Button>
              <Button
                className='mr-2'
                type='submit'
                disabled={isSubmitting || !isValid}
              >
                Send invite
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default InviteAccountUserModal
