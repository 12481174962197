import { IconProps } from './types'

export const SupportedProjects = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.345 6.46251C12.8435 6.26485 14.0001 4.98479 14.0001 3.43622C14.0001 3.19499 13.8046 3 13.5626 3H13.1251C11.9767 3 10.9759 3.63121 10.4523 4.56353C10.9267 5.07753 11.248 5.73454 11.345 6.46251Z'
        fill='currentColor'
      />
      <path
        d='M7.43757 3.87244C7.19557 3.87244 7.00007 4.06743 7.00007 4.30867C7.00007 5.99489 8.37137 7.36222 10.0626 7.36222V9.54333C10.0626 9.78457 10.2581 9.97955 10.5001 9.97955C10.7421 9.97955 10.9376 9.78457 10.9376 9.54333V6.926C10.9376 5.23978 9.56631 3.87244 7.87508 3.87244H7.43757Z'
        fill='currentColor'
      />
      <path
        d='M18.4908 13.2481C19.0471 12.8378 19.1658 12.0548 18.7564 11.4973C18.3471 10.9399 17.5658 10.8208 17.0096 11.2311L13.2689 13.9934H9.50009C9.22509 13.9934 9.00009 13.7679 9.00009 13.4923C9.00009 13.2168 9.22509 12.9912 9.50009 12.9912H12.0001C12.5533 12.9912 13.0001 12.5433 13.0001 11.989C13.0001 11.4348 12.5533 10.9869 12.0001 10.9869H7.05319C6.14381 10.9869 5.26255 11.2938 4.55316 11.8638L3.15003 12.9912H2.00001C1.44688 12.9912 1 13.439 1 13.9934V15.9978C1 16.5521 1.44688 17 2.00001 17H12.0126C12.922 17 13.8033 16.7087 14.5345 16.17L18.4908 13.2481Z'
        fill='currentColor'
      />
    </svg>
  )
}
