import { ComponentType, useCallback, useState } from 'react'
import { FaCog, FaSignOutAlt } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import { useAuth } from 'src/providers/AuthProvider'
import { useUser } from 'src/providers/UserProvider'

import { Divider } from 'src/components/atoms/Divider'
import { BulkGift, CrowdFund, Gift, OneOff } from 'src/components/atoms/Icons'
import { IconProps } from 'src/components/atoms/Icons/types'
import { InternalLink, OnClickLink } from 'src/components/atoms/Link'
import { Switch } from 'src/components/atoms/Switch'
import { MenuSectionTitle } from 'src/components/atoms/Typography'
import { AccountInfo } from 'src/components/molecules/AccountInfo'
import { MenuItem, SubMenu } from 'src/components/molecules/MenuDropdown'
import { UserInfo } from 'src/components/molecules/UserInfo'

import {
  bulkGiftPath,
  donatePath,
  giftPath,
  listRegistriesPath,
  viewAccountDetailPath
} from 'src/config/paths'

import { LinkOption, LinkOptionType } from '../../types'

const userActions: {
  label: string
  icon: ComponentType<IconProps>
  path: string
}[] = [
  {
    label: 'Donate',
    icon: OneOff,
    path: donatePath()
  },
  {
    label: 'Gift',
    icon: Gift,
    path: giftPath()
  },
  {
    label: 'Bulk gift',
    icon: BulkGift,
    path: bulkGiftPath()
  },
  {
    label: 'Tree registries',
    icon: CrowdFund,
    path: listRegistriesPath
  }
]

export type MobileAccountMenuProps = {
  links: LinkOption[]
}

export const MobileAccountMenu = ({ links }: MobileAccountMenuProps) => {
  const { isStaff, isAdvisor, logout, login, signup } = useAuth()
  const { isAdminMode, setAdminMode, user } = useUser()
  const [expandedMenuItem, setExpandedMenuItem] = useState('')
  const handleChangeAdminToggle = () => {
    setAdminMode(!isAdminMode)
  }

  const renderLink = useCallback(
    (link: LinkOption) => {
      if (link.options?.length) {
        return (
          <>
            <OnClickLink
              color='black'
              onClick={() => {
                setExpandedMenuItem(prev => {
                  if (prev === link.label) return ''
                  return link.label
                })
              }}
            >
              <div className='flex justify-between cursor-pointer text-nowrap items-center text-[16px] font-medium px-5 py-[13px] hover:bg-lightGrey'>
                {link.label}
                <span>
                  {expandedMenuItem === link.label ? (
                    <MdKeyboardArrowUp size='20px' />
                  ) : (
                    <MdKeyboardArrowDown size='20px' />
                  )}
                </span>
              </div>
            </OnClickLink>
            {expandedMenuItem === link.label ? (
              <div>
                {link.options?.map((option, i) => {
                  if (option.type === LinkOptionType.Seperator)
                    return <div key={i} />
                  return <MenuItem key={i} option={option} />
                })}
                {link.subMenu && link.subMenu.subMenuOptions?.length ? (
                  <SubMenu subMenu={link.subMenu} />
                ) : null}
              </div>
            ) : null}
          </>
        )
      }

      if (!link.path) return null
      return (
        <div className='text-nowrap text-[16px] font-medium px-5 py-[13px] hover:bg-lightGrey '>
          <InternalLink
            className='block'
            href={link.path!}
            color='black'
            highlightActive
            onClick={() => setExpandedMenuItem('')}
          >
            {link.label}
          </InternalLink>
        </div>
      )
    },
    [expandedMenuItem]
  )

  return (
    <div className='z-0 rounded-none bg-white shadow-[0px_4px_8px_2px_rgba(51,51,51,0.15)]'>
      {/* admin mode switch */}
      <div className='bg-mostlyGreen'>
        {isStaff || isAdvisor ? (
          <>
            <div className='space-x-4 p-5 text-white'>
              <span>{isAdminMode ? 'Admin' : 'App'}</span>
              <Switch
                title='Admin'
                checked={isAdminMode}
                onChange={handleChangeAdminToggle}
              />
            </div>
            <Divider className='!m-0' />
          </>
        ) : null}
        <div className='bg-mostlyGreen p-5'>
          <UserInfo />
        </div>
      </div>

      <div id='MENULINKS'>{links.map(renderLink)}</div>
      <Divider className='!m-0' />
      {isAdminMode ? null : (
        <>
          <div className='flex flex-col pt-5 pb-[10px]'>
            <MenuSectionTitle className='text-osloGrey pb-[10px] px-5'>
              Fund native trees
            </MenuSectionTitle>
            {userActions.map(action => {
              const Icon = action.icon
              return (
                <InternalLink
                  key={action.label}
                  className='hover:bg-lightGrey px-5 py-[13px]'
                  color='black'
                  href={action.path}
                  highlightActive
                >
                  <div className='flex flex-row gap-[10px]'>
                    <Icon className='self-start flex-grow-0 flex-shrink-0 w-[20px] h-[24px] text-primary' />
                    <span>{action.label}</span>
                  </div>
                </InternalLink>
              )
            })}
          </div>
          <Divider className='!m-0' />
        </>
      )}
      {user?.Account ? (
        <div className='p-5'>
          <AccountInfo />
        </div>
      ) : null}
      <Divider className='!m-0' />
      {user ? (
        <>
          <InternalLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            highlightActive
            href={viewAccountDetailPath}
          >
            Account settings <FaCog />
          </InternalLink>
          <Divider className='!m-0' />
          <OnClickLink
            color='black'
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            onClick={logout}
          >
            Log out <FaSignOutAlt />
          </OnClickLink>
        </>
      ) : (
        <>
          <OnClickLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            onClick={signup}
          >
            Sign up
          </OnClickLink>
          <Divider className='!m-0' />
          <OnClickLink
            color='black'
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            onClick={login}
          >
            Log in
          </OnClickLink>
        </>
      )}
    </div>
  )
}
